import clsx from 'clsx'
import React from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router'
import {CSSTransition} from 'react-transition-group'
import {
  KTSVG,
  checkIsActive,
  checkIsActiveSubRoute,
  isFeatureEnabled,
} from '../../../../_gori/helpers'
import {Link} from 'react-router-dom'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {FEATURES, ROUTES, USER_STATUS} from '../../../../_gori/constants'

const SettingsMenu: React.FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {routes} = UseRoutesPermission()

  let menuTab = [
    {
      label: 'ACCOUNT',
      sub_route: [
        ROUTES.SETTINGS.PROFILE,
        ROUTES.SETTINGS.GENERAL,
        ROUTES.SETTINGS.USER_MANAGEMENT,
        ROUTES.SETTINGS.API_KEYS,
      ],
      sub_menu: [
        {label: 'PROFILE', path: ROUTES.SETTINGS.PROFILE},
        ...(routes.ADMINS.hasPermission ? [{label: 'GENERAL', path: ROUTES.SETTINGS.GENERAL}] : []),
        ...(routes.USERS_VIEW.hasPermission && isFeatureEnabled(FEATURES.USER_MANAGEMENT)
          ? [
              {
                label: 'USER_MANAGEMENT',
                path: `${ROUTES.SETTINGS.USER_MANAGEMENT}?status=${USER_STATUS.ACTIVE}`,
              },
            ]
          : []),
        ...(isFeatureEnabled(FEATURES.API_KEYS) && routes.ADMINS.hasPermission
          ? [{label: 'API_KEYS', path: ROUTES.SETTINGS.API_KEYS}]
          : []),
      ],
    },
  ]

  const shippingMenu = [
    ...(routes.SETTINGS_ADDRESSES.hasPermission
      ? [{label: 'SHIPPING_ADDRESSES', path: ROUTES.SETTINGS.SHIPPING_ADDRESSES}]
      : []),
    ...(routes.SETTINGS_PACKAGES.hasPermission
      ? [{label: 'PACKAGES', path: ROUTES.SETTINGS.PACKAGES}]
      : []),
    ...(routes.SETTINGS_PRESETS.hasPermission
      ? [{label: 'PRESETS', path: ROUTES.SETTINGS.PRESETS}]
      : []),
    ...(routes.SETTINGS_LABELS_SLIPS.hasPermission && isFeatureEnabled(FEATURES.LABELS_SLIPS)
      ? [{label: 'LABELS_AND_PACKING_SLIPS', path: ROUTES.SETTINGS.LABELS_SLIPS}]
      : []),
  ]

  if (shippingMenu.length > 0) {
    menuTab.push({
      label: 'SHIPPING',
      sub_route: shippingMenu.map((item) => item.path),
      sub_menu: shippingMenu,
    })
  }

  const storeSetUpMenu = [
    ...(isFeatureEnabled(FEATURES.MARKETPLACE) && routes.ADMINS.hasPermission
      ? [{label: 'STORE_CONNECTIONS', path: ROUTES.SETTINGS.CONNECTIONS.BASE}]
      : []),
  ]

  if (storeSetUpMenu.length > 0) {
    menuTab.push({
      label: 'STORE_SETUP',
      sub_route: [ROUTES.SETTINGS.CONNECTIONS.BASE],
      sub_menu: storeSetUpMenu,
    })
  }

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='d-flex flex-column'>
        {Object.entries(menuTab).map(([key, value], index) => {
          return (
            <div key={index} className='text-gray-700 mb-3'>
              <div
                className={clsx(
                  'rounded-2 px-5 py-2 fw-bolder fs-6 bg-light-secondary text-active-primary',
                  {
                    'active bg-light-primary': checkIsActiveSubRoute(value.sub_route, pathname),
                  }
                )}
              >
                {intl.formatMessage({id: value.label})}
              </div>
              <div className='d-flex flex-column'>
                {value.sub_menu.map((route, index) => {
                  return (
                    <Link
                      key={index}
                      to={route.path}
                      className={clsx(
                        'px-5 py-2 text-gray-700 fs-7 fw-bolder text-active-primary ',
                        {
                          active: checkIsActive(pathname, route.path),
                          'bg-hover-white text-hover-gray-900': !checkIsActive(
                            pathname,
                            route.path
                          ),
                        }
                      )}
                    >
                      <KTSVG
                        path='/media/gori/arrows/arrow_right.svg'
                        className={clsx('me-2', {
                          'text-gray-700': !checkIsActive(pathname, route.path),
                          'text-primary': checkIsActive(pathname, route.path),
                        })}
                      />
                      {intl.formatMessage({id: route.label})}
                    </Link>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </CSSTransition>
  )
}

export {SettingsMenu}
