import clsx from 'clsx'
import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {convertCurrency, toAbsoluteUrl} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {SelectNoFormik, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {useAuth} from '../../../auth'
import {OPTIONS_ACTIVITY} from '../../core/_const'
import DashboardService from '../../core/_requests'
import {ActiveLogSkeleton} from '../skeleton/ActiveLogSkeleton'
import {ROUTES} from '../../../../../_gori/constants/_routes'

type Props = {
  loading?: boolean
  onLoading: any
}

const ActivityLog: React.FC<Props> = ({loading, onLoading}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {loadingSwitch} = useAuth()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [activityLogs, setActivityLogs] = useState<any>()
  const [newUser, setNewUser] = useState<boolean>(true)
  const [activityLogsLoading, setActivityLogsLoading] = useState<boolean>(false)

  const [filter, setFilter] = useState<any>(OPTIONS_ACTIVITY[0])

  const getActivityLogs = useCallback(async () => {
    setActivityLogsLoading(true)
    const config = {
      params: {
        type: filter?.value,
      },
      cancelToken: newCancelToken(),
    }

    try {
      const response = await DashboardService.getActivities(config)
      setActivityLogs(response.activity_logs)
      setNewUser(response.new)
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      onLoading('activityLog')
      setActivityLogsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.value, isCancel, newCancelToken, onLoading])

  useEffect(() => {
    if (loadingSwitch) return

    getActivityLogs()
  }, [getActivityLogs, loadingSwitch])

  const activityLog = () => {
    return (
      <div className='timeline-label'>
        {activityLogs &&
          activityLogs.map((item, index) => (
            <div className='timeline-item' key={index}>
              <div className='timeline-label fw-bolder text-gray-800 fs-8'>{item.date}</div>
              <div className='timeline-badge'>
                <i
                  className={clsx('fa fa-genderless dashboard-theme-color-green fs-1', {
                    'dashboard__activity__color-green': item.type === 'label',
                    'dashboard__activity__color-purple': item.type === 'deposit',
                  })}
                  aria-hidden='true'
                ></i>
              </div>
              <div className='timeline-content d-flex h-40'>
                <span className='fw-bolder text-gray-800 ps-3'>
                  <span
                    className={clsx('fs-5 me-3', {
                      'dashboard__activity__color-green': item.type === 'label',
                      'dashboard__activity__color-purple': item.type === 'deposit',
                    })}
                  >
                    {item.type === 'deposit'
                      ? `${convertCurrency(item?.value, undefined, false)}`
                      : convertCurrency(item?.value, '', false)}
                  </span>
                  {item.type === 'label'
                    ? `${intl.formatMessage({id: 'LABELS_CREATED'})}`
                    : `${intl.formatMessage({id: 'DEPOSITS_MADE'})}`}
                </span>
              </div>
            </div>
          ))}
      </div>
    )
  }
  const activityForNewUser = () => {
    return (
      <div className='mt-3 mb-5 text-center'>
        <img
          src={toAbsoluteUrl('/media/gori/dashboard/activity-log-no-data.png')}
          className='w-200px'
          alt=''
        />
        <div
          className='shipment-dashboard-text'
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'ACTIVITY_LOG_TEXT'}),
          }}
        ></div>
        <Link to={ROUTES.ORDERS.CREATE} className='link-primary fw-bolder'>
          <button type='submit' className='btn btn-lg btn-primary fw-bolder me-4'>
            {intl.formatMessage({id: 'LET_SHIP'})} 📦
          </button>
        </Link>
      </div>
    )
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loading || activityLogsLoading ? (
          <ActiveLogSkeleton />
        ) : (
          <div className='dashboard__activity card card-xl-stretch shadow-sm'>
            <div className='card-body d-flex flex-column'>
              <div className='d-flex pb-3 justify-content-between'>
                <div className='text-dark fw-bolder opacity-75 fs-3'>
                  {intl.formatMessage({id: 'ACTIVITY_LOG'})}
                </div>
                <SelectNoFormik
                  options={OPTIONS_ACTIVITY}
                  value={{label: intl.formatMessage({id: filter.label}), value: filter.value}}
                  onChange={(e) => setFilter(e)}
                  hasUseIntl={true}
                />
              </div>
              <div className='pt-5'>{newUser ? activityForNewUser() : activityLog()}</div>
            </div>
          </div>
        )}
      </CSSTransition>
    </>
  )
}

export {ActivityLog}
