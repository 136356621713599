import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {useAuth} from '../../../../auth'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import SettingsService from '../../../core/_requests'
import {toAbsoluteUrl} from '../../../../../../_gori/helpers'
import {Button} from '../../../../../../_gori/partials/widgets'
import {toast} from 'react-toastify'
import {OrderType} from '../../../../orders/core/_const'
import {ROUTES} from '../../../../../../_gori/constants'

const ConnectionsPage: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {loadingSwitch} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const [connections, setConnections] = useState<any>()
  const [isLoadingConnections, setIsLoadingConnections] = useState<boolean>(false)
  const [isLoadingShipStationConnection, setIsLoadingShipStationConnection] =
    useState<boolean>(false)

  const getConnections = useCallback(async () => {
    const config = {
      cancelToken: newCancelToken(),
    }
    setIsLoadingConnections(true)
    await SettingsService.connections(config)
      .then((response) => {
        setConnections(response.connections)
      })
      .catch((error) => {
        if (isCancel(error)) return
      })
      .finally(() => {
        setIsLoadingConnections(false)
      })
  }, [isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return

    getConnections()

    return () => {
      setConnections(undefined)
    }
  }, [loadingSwitch, getConnections])

  const handleShipStationDisconnect = useCallback(
    async (store_type) => {
      const config = {
        params: {
          store_type: store_type,
        },
        cancelToken: newCancelToken(),
      }
      setIsLoadingShipStationConnection(true)

      await SettingsService.disconnect(config)
        .then(async () => {
          await getConnections()
          navigate(ROUTES.SETTINGS.CONNECTIONS.BASE)
          toast.success(intl.formatMessage({id: 'DISCONNECTED_SUCCESSFULLY'}))
        })
        .catch((error) => {
          if (isCancel(error)) return
        })
        .finally(() => {
          setIsLoadingShipStationConnection(false)
        })
    },
    [getConnections, intl, isCancel, navigate, newCancelToken]
  )

  const connectToShipStation = () => {
    navigate(ROUTES.SETTINGS.CONNECTIONS.SHIPSTATION)
  }

  const connectToShopify = () => {
    navigate(ROUTES.SETTINGS.CONNECTIONS.SHOPIFY)
  }

  const connectToWalmart = () => {
    navigate(ROUTES.SETTINGS.CONNECTIONS.WALMART)
  }

  const connectToTikTok = () => {
    navigate(ROUTES.SETTINGS.CONNECTIONS.TIKTOK)
  }

  const connectToEbay = () => {
    navigate(ROUTES.SETTINGS.CONNECTIONS.EBAY)
  }

  const connectToTemu = () => {
    navigate('/settings/connections/temu')
  }

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='card h-100'>
        <div className='card-header card-header-stretch'>
          <div className='card-title'>
            <h3>{intl.formatMessage({id: 'STORE_CONNECTIONS'})}</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='store-connections-cards row justify-content-center justify-content-xl-start gy-5 g-xl-7 mx-xxl-23 fix-1400'>
            <div className='col-xl-3 mw-300px'>
              <div className='card shadow-sm h-210'>
                <div className='card-body d-flex flex-column'>
                  <div className='d-flex flex-column justify-content-between align-items-center pb-5'>
                    <div className={'d-flex flex-row align-items-center gap-2'}>
                      <img
                        alt='shipstation'
                        src={toAbsoluteUrl(
                          '/media/gori/settings/store-connections/shipstation-logo.png'
                        )}
                        className={'h-140px mb-13'}
                      />
                    </div>
                    <div>
                      <Button
                        className={`btn btn-sm fs-sm-5 text-nowrap ${
                          connections?.shipstation?.length > 0 ? 'btn-danger' : 'btn-primary'
                        }`}
                        label={intl.formatMessage({
                          id: connections?.shipstation?.length > 0 ? 'DISCONNECT' : 'CONNECT',
                        })}
                        loadingText={intl.formatMessage({
                          id: connections?.shipstation?.length > 0 ? 'DISCONNECT' : 'CONNECT',
                        })}
                        loading={isLoadingConnections || isLoadingShipStationConnection}
                        disabled={isLoadingConnections || isLoadingShipStationConnection}
                        event={
                          connections?.shipstation?.length > 0
                            ? () => handleShipStationDisconnect(OrderType.SHIPSTATION)
                            : connectToShipStation
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 mw-300px'>
              <div className='card shadow-sm h-210'>
                <div className='card-body d-flex flex-column'>
                  <div className='d-flex flex-column justify-content-between align-items-center pb-5'>
                    <div className={'d-flex flex-row align-items-center gap-2'}>
                      <img
                        alt='shopify'
                        src={toAbsoluteUrl(
                          '/media/gori/settings/store-connections/shopify-logo.png'
                        )}
                        className={'h-140px mb-13'}
                      />
                    </div>
                    <div>
                      <Button
                        className={`btn btn-sm btn-primary fs-sm-5 text-nowrap`}
                        label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loading={isLoadingConnections}
                        disabled={isLoadingConnections}
                        event={connectToShopify}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 mw-300px'>
              <div className='card shadow-sm h-210'>
                <div className='card-body d-flex flex-column'>
                  <div className='d-flex flex-column justify-content-between align-items-center pb-5'>
                    <div className={'d-flex flex-row align-items-center gap-2'}>
                      <img
                        alt='walmart'
                        src={toAbsoluteUrl(
                          '/media/gori/settings/store-connections/walmart-logo.png'
                        )}
                        className={'h-140px mb-13'}
                      />
                    </div>
                    <div>
                      <Button
                        className={`btn btn-sm btn-primary fs-sm-5 text-nowrap`}
                        label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loading={isLoadingConnections}
                        disabled={isLoadingConnections}
                        event={connectToWalmart}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 mw-300px'>
              <div className='card shadow-sm h-210'>
                <div className='card-body d-flex flex-column'>
                  <div className='d-flex flex-column justify-content-between align-items-center pb-5'>
                    <div className={'d-flex flex-row align-items-center gap-2'}>
                      <img
                        alt='shopify'
                        src={toAbsoluteUrl(
                          '/media/gori/settings/store-connections/tiktok-logo.png'
                        )}
                        className={'h-140px mb-13'}
                      />
                    </div>
                    <div>
                      <Button
                        className={`btn btn-sm btn-primary fs-5 text-nowrap`}
                        label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loading={isLoadingConnections}
                        disabled={isLoadingConnections}
                        event={connectToTikTok}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 mw-300px'>
              <div className='card shadow-sm h-210'>
                <div className='card-body d-flex flex-column'>
                  <div className='d-flex flex-column justify-content-between align-items-center pb-5'>
                    <div className={'d-flex flex-row align-items-center gap-2'}>
                      <img
                        alt='ebay'
                        src={toAbsoluteUrl('/media/gori/settings/store-connections/ebay-logo.png')}
                        className={'h-140px mb-13'}
                      />
                    </div>
                    <div>
                      <Button
                        className={`btn btn-sm btn-primary fs-5 text-nowrap`}
                        label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loading={isLoadingConnections}
                        disabled={isLoadingConnections}
                        event={connectToEbay}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 mw-300px'>
              <div className='card shadow-sm h-210'>
                <div className='card-body d-flex flex-column'>
                  <div className='d-flex flex-column justify-content-between align-items-center pb-5'>
                    <div className={'d-flex flex-row align-items-center gap-2'}>
                      <img
                        alt='temu'
                        src={toAbsoluteUrl('/media/gori/settings/store-connections/temu-logo.png')}
                        className={'h-140px mb-13'}
                      />
                    </div>
                    <div>
                      <Button
                        className={`btn btn-sm btn-primary fs-5 text-nowrap`}
                        label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                        loading={isLoadingConnections}
                        disabled={isLoadingConnections}
                        event={connectToTemu}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 mw-300px'>
              <div className='card shadow-sm h-210'>
                <div className='card-body d-flex flex-column'>
                  <div className='d-flex flex-column justify-content-between align-items-center pb-5'>
                    <div className={'d-flex flex-row align-items-center gap-2'}>
                      <img
                        alt='amazon'
                        src={toAbsoluteUrl(
                          '/media/gori/settings/store-connections/amazon-logo-lock.png'
                        )}
                        className={'h-140px mb-13'}
                      />
                    </div>
                    <div>
                      <p
                        className='btn fw-bolder m-0 text-nowrap'
                        style={{
                          fontSize: '18px',
                          lineHeight: '17.23px',
                          letterSpacing: '-0.396px',
                          color: '#94A3B8',
                        }}
                      >
                        {intl.formatMessage({id: 'COMING_SOON'})}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export {ConnectionsPage}
