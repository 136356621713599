import {isFeatureEnabled, KTSVG} from '../../../../_gori/helpers'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {FEATURES} from '../../../../_gori/constants'

const HelpCenterWidget = function (props) {
  const intl = useIntl()
  const [showPopover, setShowPopover] = useState<boolean>(false)

  return (
    <>
      {isFeatureEnabled(FEATURES.HELP_CENTER) && (
        <div onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)}>
          <OverlayTrigger
            show={showPopover}
            key={'top'}
            placement={'top'}
            overlay={
              <Tooltip id='help-center-widget-tooltip-top'>
                <a
                  href={props.data.href}
                  rel='noreferrer'
                  target='_blank'
                  className='text-decoration-underline text-primary'
                >
                  {intl.formatMessage({id: props.data.label})}
                </a>
              </Tooltip>
            }
          >
            <Button
              id='btn-help-center-widget'
              className='btn-help-center-widget bg-transparent position-fixed bottom-0 end-0 p-0 me-4 mb-7 mb-md-8 mb-lg-10'
              children={
                <KTSVG
                  path={`/media/gori/onboard/label/${
                    showPopover ? 'question-mark-hover' : 'question-mark'
                  }.svg`}
                  className='question-help svg-icon svg-icon-4x p-0 m-0'
                />
              }
            />
          </OverlayTrigger>
        </div>
      )}
    </>
  )
}

export {HelpCenterWidget}
