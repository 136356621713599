import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import SettingsService from '../../../../core/_requests'
import {Button, ButtonBack} from '../../../../../../../_gori/partials/widgets'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import * as Yup from 'yup'
import {OrderType} from '../../../../../orders/core/_const'
import {StoresListing} from '../StoresListing'
import {isEmpty} from 'lodash'
import {ROUTES} from '../../../../../../../_gori/constants'

const ConnectEbay: React.FC = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {newCancelToken} = useCancelToken()
  const validationSchema = Yup.object().shape({})

  const initialValues = {
    store_type: OrderType.EBAY,
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true)
      const config = {cancelToken: newCancelToken()}
      await SettingsService.getEbayConsentLink(config)
        .then((res) => {
          setIsLoading(false)
          window.location.replace(res)
        })
        .catch((error) => {
          let message = error?.response?.data?.error?.message ?? ''
          toast.error(isEmpty(message) ? intl.formatMessage({id: 'CONNECTION_FAILED'}) : message)
        })
        .finally(() => {})
    },
  })

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='card p-10 rounded-bottom-0'>
          <div className='card-title d-flex justify-content-between'>
            <div className='d-flex gap-4 align-items-center'>
              <ButtonBack basePath={ROUTES.SETTINGS.CONNECTIONS.BASE} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} eBay
              </span>
            </div>
            <Button
              className={clsx('btn btn-primary text-end', {
                'pe-none': isLoading,
                'cursor-no-drop': isLoading,
              })}
              loading={isLoading}
              loadingText={intl.formatMessage({id: 'CONNECT'})}
              label={intl.formatMessage({id: 'CONNECT'})}
              event={formik.submitForm}
            />
          </div>
        </div>
      </CSSTransition>
      <StoresListing storeType={OrderType.EBAY} />
    </>
  )
}

export {ConnectEbay}
