export const HELP_CENTER_WIDGET_PARAMS = {
  ORDER: {
    label: 'QUESTION_TOOLTIP_AT_ORDER_PAGE',
    href: '/help-center/create-shipment/manual-order',
  },
  BATCHES: {
    label: 'QUESTION_TOOLTIP_AT_BATCHES_PAGE',
    href: '/help-center/create-shipment/batch-order',
  },
  DEPOSITS: {
    label: 'QUESTION_TOOLTIP_AT_DEPOSITS',
    href: '/help-center/payment',
  },
}
