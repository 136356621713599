import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import am5geodata_usaLow from '@amcharts/amcharts5-geodata/usaLow'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {isEmpty} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import CountUp from 'react-countup'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {DatePicker} from '../../../../../_gori/helpers/components/DatePicker'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {useAuth} from '../../../auth'
import {VALUE_SHIPMENT_MAP} from '../../core/_const'
import DashboardService from '../../core/_requests'
import {ServicesUsed} from './ServicesUsed'
import {Link, useSearchParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_gori/helpers'
import {ROUTES} from '../../../../../_gori/constants/_routes'

type Props = {
  loading?: boolean
  onLoading: any
}

const ShipmentMap: React.FC<Props> = ({loading, onLoading}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams] = useSearchParams()
  const {loadingSwitch} = useAuth()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [shipmentMap, setShipmentMap] = useState<any>()
  const [shipmentMapLoading, setShipmentMapLoading] = useState<boolean>(false)
  const [checkedShipment, setCheckedShipment] = useState<string>(VALUE_SHIPMENT_MAP.CHECK.TO)
  const [globalMap, setGlobalMap] = useState<string>(VALUE_SHIPMENT_MAP.TYPE.DOMESTIC)

  const initValue = useMemo(() => {
    return {
      start_date: moment().subtract(29, 'days').startOf('day').format(),
      end_date: moment().endOf('day').format(),
    }
  }, [])

  const handleChangGlobalMap = (e) => {
    setGlobalMap(e.target.value)
  }

  const handleChangeCheckedShipment = (e) => {
    setCheckedShipment(e.target.value)
  }

  const getShipmentMap = useCallback(async () => {
    setShipmentMapLoading(true)
    const config = {
      params: {
        start_date: searchParams.get('date_from') || initValue.start_date,
        end_date: searchParams.get('date_to') || initValue.end_date,
      },
      cancelToken: newCancelToken(),
    }

    setShipmentMap({})

    try {
      const response = await DashboardService.getShipmentMap(config)
      setShipmentMap(response)
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      onLoading('shipmentsMap')
      setShipmentMapLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue, isCancel, newCancelToken, onLoading, searchParams])

  useEffect(() => {
    if (loadingSwitch) {
      return
    }
    getShipmentMap()
  }, [getShipmentMap, loadingSwitch])

  useEffect(() => {
    if (loading || shipmentMapLoading || !chartRef.current || isEmpty(shipmentMap)) {
      return
    }

    let root = am5.Root.new('chart-dashboard-shipments-map')
    root.setThemes([am5themes_Animated.new(root)])
    root?._logo?.dispose()

    if (globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL) {
      // Create the map chart
      // https://www.amcharts.com/docs/v5/charts/map-chart/
      let chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'translateX',
          panY: 'translateY',
          projection: am5map.geoMercator(),
        })
      )
      chart.root.dom.style.height = '425px'
      // chart.root.dom.style.width = '900px'

      // World Series
      // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
      let polygonSeriesWorld = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          valueField: 'value',
          calculateAggregates: true,
          exclude: ['AQ'],
          fill: am5.color('#E2E8F0'),
        })
      )

      polygonSeriesWorld.mapPolygons.template.setAll({
        tooltipText: '{name}: {value}',
      })
      polygonSeriesWorld.set('heatRules', [
        {
          target: polygonSeriesWorld.mapPolygons.template,
          dataField: 'value',
          min: am5.color('#9bd5cf'),
          max: am5.color('#0a5e54'),
          key: 'fill',
        },
      ])
      if (checkedShipment === VALUE_SHIPMENT_MAP.CHECK.TO) {
        polygonSeriesWorld.data.setAll(Object.values(shipmentMap?.global?.to ?? []))
      } else {
        polygonSeriesWorld.data.setAll(Object.values(shipmentMap?.global?.from ?? []))
      }

      // @ts-ignore
      chart.chartContainer.get('background').events.on('click', function () {
        chart.goHome()
      })
      chart.appear(1000, 100)
    } else {
      let chart = root.container.children.push(
        am5map.MapChart.new(root, {
          projection: am5map.geoAlbersUsa(),
          layout: root.horizontalLayout,
        })
      )
      chart.root.dom.style.height = '425px'
      // chart.root.dom.style.width = '900px'

      let polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_usaLow,
          valueField: 'value',
          calculateAggregates: true,
          exclude: ['antarctica'],
          fill: am5.color('#E2E8F0'),
        })
      )

      polygonSeries.mapPolygons.template.setAll({
        tooltipText: '{name}: {value}',
      })
      polygonSeries.set('heatRules', [
        {
          target: polygonSeries.mapPolygons.template,
          dataField: 'value',
          min: am5.color('#9bd5cf'),
          max: am5.color('#0a5e54'),
          key: 'fill',
        },
      ])
      if (checkedShipment === VALUE_SHIPMENT_MAP.CHECK.TO) {
        polygonSeries.data.setAll(Object.values(shipmentMap?.domestic?.to ?? []))
      } else {
        polygonSeries.data.setAll(Object.values(shipmentMap?.domestic?.from ?? []))
      }

      // @ts-ignore
      chart.chartContainer.get('background').events.on('click', function () {
        chart.goHome()
      })
      chart.appear(1000, 100)
    }

    return () => {
      root.dispose()
    }
  }, [checkedShipment, globalMap, shipmentMap, loading, shipmentMapLoading])

  const shipmentRegion = () => {
    return (
      <>
        <div className='row'>
          {loading || shipmentMapLoading ? (
            <div>
              <div className='d-flex flex-stack justify-content-between mb-2 placeholder-wave'>
                <span className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <span className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              {Array.from({length: 4}).map((item, key) => (
                <div className='d-flex flex-column w-100 me-2 mb-5' key={key}>
                  <div className='d-flex flex-stack justify-content-between mb-2 placeholder-wave'>
                    <span className='fs-6 col-5 placeholder placeholder-lg rounded-2 bg-secondary' />
                    <span className='fs-6 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                  <div className='d-flex flex-stack justify-content-between mb-2 placeholder-wave'>
                    <span className='fs-6 col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <div className='d-flex flex-stack justify-content-between mb-2'>
                <span className='text-muted fw-bolder me-2 fs-7 fw-bold'>
                  {intl.formatMessage({id: 'REGION'})}
                </span>
                <span className='text-muted fw-bolder me-2 fs-7 fw-bold'>
                  {intl.formatMessage({id: 'PACKAGE'})}
                </span>
              </div>
              {shipmentMap &&
                Object.entries(
                  checkedShipment === VALUE_SHIPMENT_MAP.CHECK.TO
                    ? globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                      ? shipmentMap?.global?.to_top_region ?? []
                      : shipmentMap?.domestic?.to_top_region ?? []
                    : globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                    ? shipmentMap?.global?.from_top_region ?? []
                    : shipmentMap?.domestic?.from_top_region ?? []
                ).map(([key, region]: [any, any], index) => {
                  const total =
                    checkedShipment === VALUE_SHIPMENT_MAP.CHECK.TO
                      ? globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                        ? shipmentMap?.global?.to_total
                        : shipmentMap?.domestic?.to_total
                      : globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                      ? shipmentMap?.global?.from_total
                      : shipmentMap?.domestic?.from_total
                  const percent = Math.round((region.value / total) * 100)
                  return (
                    <div className='d-flex flex-column w-100 me-2 mb-5' key={key}>
                      <div className='d-flex flex-stack mb-2 justify-content-between'>
                        <span className='fw-bolder me-2 fs-7 fw-bold'>{region.name}</span>
                        <span className='fw-bolder me-2 fs-7 fw-bold'>{region.value}</span>
                      </div>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement='auto'
                        overlay={
                          <Popover>
                            <Popover.Header as='h3'>{percent}%</Popover.Header>
                          </Popover>
                        }
                      >
                        <div className='progress h-4px w-100'>
                          <div
                            role='progressbar'
                            className={`progress-bar dashboard__shipments__bg-progress--${index}`}
                            style={{width: `${percent}%`}}
                            aria-valuenow={percent}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  )
                })}
            </div>
          )}
        </div>
        <div>
          {loading || shipmentMapLoading ? (
            <div className='row mt-3'>
              <div className='d-flex flex-fill flex-wrap col-12'>
                {Array.from({length: 3}).map((item, key) => (
                  <div className='col-lg-4 mb-2'>
                    <div className='dashboard__shipments__total opacity-75 me-2' key={key}>
                      <div className='d-flex placeholder-wave'>
                        <span className='fs-3 col-10 placeholder placeholder-lg rounded-2 bg-secondary' />
                      </div>
                      <div className='d-flex justify-content-end placeholder-wave'>
                        <span className='fs-3 col-10 placeholder placeholder-lg rounded-2 bg-secondary' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className='row mt-3'>
                <div className='col-lg-4 mb-2'>
                  <div className='dashboard__shipments__total'>
                    <div className='fw-bolder fs-7'>
                      {intl.formatMessage({id: 'TOTAL_PACKAGES'})}
                    </div>
                    <div className='d-flex justify-content-end'>
                      <div className='fs-3 text-gray-800 counted text-truncate'>
                        <CountUp
                          start={0}
                          end={
                            globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                              ? shipmentMap?.global?.total
                              : shipmentMap?.domestic?.total
                          }
                          separator=','
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 mb-2'>
                  <div className='dashboard__shipments__delivered'>
                    <div className='fw-bolder fs-7'>{intl.formatMessage({id: 'DELIVERED'})}</div>
                    <div className='d-flex justify-content-end'>
                      <div className='fs-3 text-gray-800 counted text-truncate  '>
                        <CountUp
                          start={0}
                          end={
                            globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                              ? shipmentMap?.global?.delivered
                              : shipmentMap?.domestic?.delivered
                          }
                          separator=','
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 mb-2'>
                  <div className='dashboard__shipments__delivered-percent'>
                    <div className='fw-bolder fs-7'>{intl.formatMessage({id: 'DELIVERED'})} %</div>
                    <div className='d-flex justify-content-end align-items-center'>
                      <div className='fs-3 text-gray-800 counted text-truncate'>
                        <CountUp
                          start={0}
                          end={
                            globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                              ? shipmentMap?.global?.delivered_percentage
                              : shipmentMap?.domestic?.delivered_percentage
                          }
                          separator=','
                          decimal={'.'}
                          decimals={2}
                        />
                      </div>
                      %
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='row'>
          {loading || shipmentMapLoading ? (
            <div className='row mt-3 mb-5'>
              <div className='col-12 mt-3'>
                <div className='d-flex placeholder-wave col-9 mb-4'>
                  <span className='fs-3 col-4 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
                <div className='row'>
                  <div className='col-4 d-flex placeholder-wave'>
                    <div className='h-100px w-100px fs-3 placeholder placeholder-lg bg-secondary rounded-circle' />
                  </div>
                  <div className='col-7'>
                    {Array.from({length: 3}).map((item, index) => (
                      <div
                        className='d-flex placeholder-wave justify-content-between mb-2'
                        key={index}
                      >
                        <span className='fs-3 col-8 placeholder placeholder-lg rounded-2 bg-secondary' />
                        <span className='fs-3 col-3 placeholder placeholder-lg rounded-2 bg-secondary ' />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className='mt-3 mb-5'>
                <ServicesUsed
                  services={
                    globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL
                      ? shipmentMap?.global?.services_used
                      : shipmentMap?.domestic?.services_used
                  }
                />
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  const shipmentForNewUser = () => {
    return (
      <div className='mt-3 mb-5 text-center'>
        <img
          src={toAbsoluteUrl('/media/gori/dashboard/region-no-data.png')}
          className='w-200px'
          alt=''
        />
        <div
          className='shipment-dashboard-text'
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'SHIPMENTS_DASHBOARD_TEXT'}),
          }}
        ></div>
        <Link to={ROUTES.ORDERS.CREATE} className='link-primary fw-bolder'>
          <button type='submit' className='btn btn-lg btn-primary fw-bolder me-sm-4'>
            {intl.formatMessage({id: 'GET_STARTED'})} 🚀
          </button>
        </Link>
      </div>
    )
  }
  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='dashboard__shipments card card-xl-stretch shadow-sm'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex flex-wrap gap-3 pb-3 justify-content-between'>
              {loading || shipmentMapLoading ? (
                <>
                  <h1 className='col-6 placeholder-wave'>
                    <span className='col-5 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </h1>
                  <div className='col-6 d-flex flex-row-reverse placeholder-wave'>
                    <span className='btn col-3 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  </div>
                </>
              ) : (
                <>
                  <div className='d-flex align-items-center text-dark fw-bolder opacity-75 fs-3'>
                    {intl.formatMessage({id: 'SHIPMENTS_MAP'})}
                  </div>
                  <div className='d-flex flex-wrap gap-2 align-items-center'>
                    <div className='d-flex align-items-center form-check pe-5 fs-8 m-0'>
                      <input
                        className='form-check-input dashboard__shipments__checkBox'
                        type='radio'
                        name='checkedInternationalShipment'
                        id='checkedInternationalShipmentDomestic'
                        value={VALUE_SHIPMENT_MAP.TYPE.DOMESTIC}
                        checked={globalMap === VALUE_SHIPMENT_MAP.TYPE.DOMESTIC}
                        onChange={handleChangGlobalMap}
                      />
                      <label
                        className='ps-2 form-check-label text-uppercase'
                        htmlFor='checkedInternationalShipmentDomestic'
                      >
                        {intl.formatMessage({id: 'DOMESTIC'})}
                      </label>
                    </div>
                    <div className='d-flex align-items-center form-check fs-8 m-0 pe-7'>
                      <input
                        className='form-check-input dashboard__shipments__checkBox'
                        type='radio'
                        name='checkedInternationalShipment'
                        id='checkedInternationalShipmentIntl'
                        value={VALUE_SHIPMENT_MAP.TYPE.GLOBAL}
                        checked={globalMap === VALUE_SHIPMENT_MAP.TYPE.GLOBAL}
                        onChange={handleChangGlobalMap}
                      />
                      <label
                        className='ps-2 form-check-label text-uppercase'
                        htmlFor='checkedInternationalShipmentIntl'
                      >
                        {intl.formatMessage({id: 'GLOBAL'})}
                      </label>
                    </div>
                    <DatePicker name={intl.formatMessage({id: 'SHIPMENT_DATES'})} />
                  </div>
                </>
              )}
            </div>
            <div className='pt-5'>
              <div className='pt-0 d-flex flex-column flex-sm-row justify-content-between'>
                <div className='col-12 col-sm-7'>
                  {loading || shipmentMapLoading ? (
                    <div className='d-flex justify-content-end placeholder-wave'>
                      <span className='fs-3 col-1 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                      <span className='fs-3 col-1 placeholder placeholder-lg rounded-2 bg-secondary' />
                    </div>
                  ) : (
                    <div className='d-flex justify-content-end'>
                      <div className='d-flex align-items-center form-check pe-5 fs-8'>
                        <input
                          className='form-check-input dashboard__shipments__checkBox'
                          type='radio'
                          name='checkedShipmentMap'
                          id='checkedFromShipmentMap'
                          value={VALUE_SHIPMENT_MAP.CHECK.FROM}
                          checked={checkedShipment === VALUE_SHIPMENT_MAP.CHECK.FROM}
                          onChange={handleChangeCheckedShipment}
                        />
                        <label
                          className='ps-2 form-check-label text-uppercase'
                          htmlFor='checkedFromShipmentMap'
                        >
                          {intl.formatMessage({id: 'FROM'})}
                        </label>
                      </div>
                      <div className='d-flex align-items-center form-check fs-8'>
                        <input
                          className='form-check-input dashboard__shipments__checkBox'
                          type='radio'
                          name='checkedShipmentMap'
                          id='checkedToShipmentMap'
                          value={VALUE_SHIPMENT_MAP.CHECK.TO}
                          checked={checkedShipment === VALUE_SHIPMENT_MAP.CHECK.TO}
                          onChange={handleChangeCheckedShipment}
                        />
                        <label
                          className='ps-2 form-check-label text-uppercase'
                          htmlFor='checkedToShipmentMap'
                        >
                          {intl.formatMessage({id: 'TO'})}
                        </label>
                      </div>
                    </div>
                  )}
                  <div className='pt-4 mb-10'>
                    <div
                      className='min-h-auto ps-4 pe-6 w-100'
                      ref={chartRef}
                      id='chart-dashboard-shipments-map'
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-4'>
                  {shipmentMap?.new ? shipmentForNewUser() : shipmentRegion()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {ShipmentMap}
