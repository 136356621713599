import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const URL = '/a1/batches'
const URL_ORDERS = '/a1/orders'
const URL_TICKETS = '/tickets'

const getA1BatchLatest = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/latest`, config)
}

const getList = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL, config)
}

const getDetail = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}`, config)
}

const getA1BatchOrders = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}/orders`, config)
}

const downloadOrdersA1Batch = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}/orders/download`, config)
}

const store = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}`, payload, config)
}

const sendToPartner = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/${id}/send-to-partner`, {}, config)
}

const printLabel = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/print`, payload, config)
}

const uniqueAddresses = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/unique-addresses`, payload, config)
}

const updateA1Batch = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/${id}`, payload, config)
}

const removeBatch = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`${URL}/${id}`, config)
}

const removeOrderA1Batch = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/${id}/remove-orders`, payload, config)
}

const getFromAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_ORDERS}/from-addresses`, payload, config)
}

const updateOrderA1Batch = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL_ORDERS}/${id}`, payload, config)
}

const massUpdateOrderA1Batch = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.patch(`${URL_ORDERS}`, payload, config)
}

const getTickets = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}/tickets`, config)
}

const getTicketDetail = (batchId: any, id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${batchId}${URL_TICKETS}/${id}`, config)
}

const postComments = (
  batchId: any,
  id: any,
  payload: any,
  config?: AxiosRequestConfig | undefined
) => {
  return RequestBase.post(`${URL}/${batchId}${URL_TICKETS}/${id}/comments`, payload, config)
}

const A1BatchService = {
  getA1BatchLatest,
  getList,
  getDetail,
  getA1BatchOrders,
  downloadOrdersA1Batch,
  store,
  sendToPartner,
  printLabel,
  uniqueAddresses,
  updateA1Batch,
  removeBatch,
  removeOrderA1Batch,
  getFromAddress,
  updateOrderA1Batch,
  massUpdateOrderA1Batch,
  getTickets,
  getTicketDetail,
  postComments,
}

export default A1BatchService
