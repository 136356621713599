import React, {useMemo, useState} from 'react'
import {PROFILE_FIELD, STEPS} from '../../core/_const'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, InputTextFormik, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {forEach, isEmpty} from 'lodash'
import {KTSVG} from '../../../../../_gori/helpers'
import {TransparentButton} from '../../../../../_gori/partials/widgets/form/TransparentButton'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import CardLayout from '../common/CardLayout'
import clsx from 'clsx'
import {InputCheckboxFormik} from '../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import OnboardService from '../../core/_requests'
import UserService from '../../../users/core/_requests'
import * as Yup from 'yup'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {SpecialOfferModal} from '../../../../../_gori/partials/widgets/modals/SpecialOfferModal'
import {useAuth} from '../../../auth'
import {Tiers} from '../../../common/components/Tiers'
import {ROUTES} from '../../../../../_gori/constants'

type Props = {
  setActiveStep: any
  onboard?: any
  prepareData?: any
  setPrepareData?: any
}
const Pricing: React.FC<Props> = ({setActiveStep, onboard, prepareData, setPrepareData}) => {
  const intl = useIntl()
  const {setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false)
  const [mndaChecked, setMndaChecked] = useState<boolean>(false)
  const [showSpecialOfferModal, setShowSpecialOfferModal] = useState<boolean>(false)
  const [currentTier, setCurrentTier] = useState<string>('standard')
  const {stringYup, regexYup} = UseYupValidate()

  const initialValues = useMemo(() => {
    let init: any = {}
    forEach(PROFILE_FIELD.SALES, (field: any) => {
      if (!isEmpty(onboard)) {
        init[field.name] = onboard?.profile?.[`${field.name}`]
      }
    })

    setMndaChecked(onboard?.profile?.mnda_terms_conditions)

    return {
      ...init,
      mnda_terms_conditions: onboard?.profile?.mnda_terms_conditions,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboard])

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      sales_rep: regexYup.inputText('SALE_REP', false, 50),
      promo_code: stringYup(50, 'PROMO_CODE', false),
      mnda_terms_conditions: Yup.boolean()
        .oneOf([true], intl.formatMessage({id: 'YOU_MUST_AGREE_TO_TERM_AND_CONDITIONS'}))
        .required(intl.formatMessage({id: 'YOU_MUST_AGREE_TO_TERM_AND_CONDITIONS'})),
    })
  }, [intl, regexYup, stringYup])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async () => {
      await handleSubmit({hasPass: false})
    },
  })

  const handleSubmit = async ({hasPass}) => {
    if (!hasPass) {
      return setShowSpecialOfferModal(true)
    }

    let payload = {
      step: 'pricing',
      data: {
        ...prepareData,
        ...formik.values,
        plan_tier: currentTier,
      },
      save_and_exit: saveAndExit,
    }

    try {
      const config = {cancelToken: newCancelToken()}
      await OnboardService.store(payload, config)
      setCurrentUser(await UserService.getUser(config))
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      if (saveAndExit) {
        navigate(ROUTES.AUTH.LOGOUT)
      } else {
        navigate(ROUTES.ORDERS.BASE)
      }
    }
  }

  const setAgreeTermsCondition = async (isChecked: boolean | ((prevState: boolean) => boolean)) => {
    setMndaChecked(isChecked)
  }

  const handleContinue = () => {
    setSaveAndExit(false)
    formik.handleSubmit()
  }

  const backStep = () => {
    setActiveStep(STEPS.single_step)
  }

  const handleCloseModal = () => {
    setShowSpecialOfferModal(false)
    handleSubmit({hasPass: true})
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showSpecialOfferModal && (
        <SpecialOfferModal
          handleClose={() => {
            handleCloseModal()
          }}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <>
          <CardLayout>
            <div className='pricing-plans pricing-page-container'>
              <div className='col-md-12'>
                <div className='text-start'>
                  <p className='fs-2 fw-bolder text-primary'>
                    {intl.formatMessage({id: 'PRICING'})}
                  </p>
                </div>
              </div>
              <div className='row d-flex justify-content-between my-10'>
                <Tiers
                  page='onboard'
                  currentTier={currentTier}
                  setCurrentTier={setCurrentTier}
                  showPopularFlag={true}
                />
              </div>
            </div>
            <div className='col-12'>
              <hr className='bg-gray-400' />
            </div>

            <div className='col-12 mt-5 sales-rep'>
              <div className='row'>
                {PROFILE_FIELD.SALES.map((field, index) => {
                  if (field.type === 'text') {
                    return (
                      <div className='col-md-4' key={index}>
                        <InputTextFormik
                          className='col-12'
                          formik={formik}
                          name={field.name}
                          disabled={formik.isSubmitting || field.disabled}
                          label={
                            <>
                              {intl.formatMessage({id: field.label})}
                              {!field.required && (
                                <i className='ms-2 text-muted fs-7'>
                                  {intl.formatMessage({id: 'OPTIONAL_LABEL'})}
                                </i>
                              )}
                            </>
                          }
                          labelClassName={clsx('fw-bolder', {required: field.required})}
                        />
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            </div>
            <div className='col-12 mt-4 terms-and-conditions'>
              <div className='row'>
                <div className={clsx('my-2')}>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <InputCheckboxFormik
                        formik={formik}
                        type='checkbox'
                        checked={mndaChecked}
                        label={
                          <span className='fw-bolder'>
                            {intl.formatMessage({id: 'CLICK_HERE_TO_AGREE_TO'})}{' '}
                            <a
                              href={ROUTES.SERVICE_TERMS.MNDA}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='fw-bolder required'
                            >
                              {intl.formatMessage({id: 'TERM_AND_CONDITIONS'})}
                            </a>
                          </span>
                        }
                        onClick={() => setAgreeTermsCondition(!mndaChecked)}
                        name={'mnda_terms_conditions'}
                        required={true}
                      />
                      {formik.touched.mnda_terms_conditions && formik.errors.mnda_terms_conditions && (
                        <span className='text-danger mt-1' role='alert'>
                          {formik.errors.mnda_terms_conditions}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-9 d-flex justify-content-end'>
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'CONTINUE'})}
                loadingText={intl.formatMessage({id: 'CONTINUE'})}
                loading={formik.isSubmitting}
                event={() => {
                  handleContinue()
                }}
                disabled={formik.isSubmitting || !isEmpty(formik.errors)}
              />
            </div>
          </CardLayout>

          <div className='d-block'>
            <div className='save-and-exit d-flex justify-content-end'>
              <TransparentButton
                className='btn onboard-form'
                label={intl.formatMessage({id: 'BACK'})}
                loadingText={intl.formatMessage({id: 'BACK'})}
                loading={formik.isSubmitting}
                event={() => backStep()}
                disabled={!isEmpty(formik.errors) || formik.isSubmitting}
                icon={<KTSVG path='/media/gori/arrows/arrow_left.svg' />}
              ></TransparentButton>
              <TransparentButton
                className='btn me-20'
                label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
                loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
                loading={formik.isSubmitting}
                event={async () => {
                  await setSaveAndExit(true)
                  formik.handleSubmit()
                }}
                disabled={!isEmpty(formik.errors) || formik.isSubmitting}
              >
                <KTSVG
                  path='/media/gori/orders/delete.svg'
                  className='m-0'
                  svgClassName='mh-10px'
                />
              </TransparentButton>
            </div>
          </div>
        </>
      </CSSTransition>
    </>
  )
}

export {Pricing}
