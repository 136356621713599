import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'
import RequestBaseTemu from '../../../api/RequestBaseTemu'

const URL = '/shipments'

const search = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL, config)
}
const get = (id?: string, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}`, config)
}
const store = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(URL, payload, config)
}

const reship = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/reship`, payload, config)
}

const storeTemu = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBaseTemu.post(URL, payload, config)
}

const reshipTemu = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBaseTemu.post(`${URL}/reship`, payload, config)
}

const refund = (id: any, payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/refund/${id}`, payload, config)
}

const print = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/print`, payload, config)
}

const printReturnLabel = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/returns`, payload, config)
}

// Packing slip
const storePackingSlip = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/packing-slips`, payload, config)
}

const printPackingSlip = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/packing-slips/print`, payload, config)
}

const ShipmentService = {
  search,
  get,
  store,
  reship,
  storeTemu,
  reshipTemu,
  refund,
  print,
  printReturnLabel,
  // Packing slip
  storePackingSlip,
  printPackingSlip,
}

export default ShipmentService
