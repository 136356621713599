import CountUp from 'react-countup'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {SwapperComponent} from '../../../assets/ts/components'
import {isFeatureEnabled, KTSVG, StorageHelpers} from '../../../helpers'
import {useMeter} from '../../../helpers/components/MeterProvider'
import UseRoutesPermission from '../../../hooks/UseRoutesPermission'
import {FEATURES} from '../../../constants/_features'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {TutorialChecklist} from '../../../../app/modules/orders'
import {TUTORIAL_CARDS} from '../../../../app/modules/orders/core/_const'
import UserService from '../../../../app/modules/users/core/_requests'
import {ROUTES} from '../../../constants/_routes'

const Topbar = () => {
  const intl = useIntl()
  const {currentUser, currentAccount} = useAuth()
  const {meter} = useMeter()
  const navigate = useNavigate()
  const {routes} = UseRoutesPermission()
  const [showChecklist, setShowChecklist] = useState<Boolean>(false)
  const [tutorialCard, setTutorialCard] = useState(null)
  const [stepsCompleted, setStepsCompleted] = useState(5)

  const handleShowChecklist = () => {
    if (showChecklist) {
      setShowChecklist(false)
    } else {
      setShowChecklist(true)
    }
  }

  const closeChecklist = () => {
    setShowChecklist(false)
  }

  useEffect(() => {
    const fetchTutorialCard = async () => {
      try {
        const settings = await UserService.getTutorialCard()
        setTutorialCard(settings)
      } catch (error) {
        console.error('Error fetching tutorial card:', error)
      }
    }
    fetchTutorialCard()
  }, [])

  const countStepsCompleted = () => {
    let completedCount = 0

    TUTORIAL_CARDS.forEach((card) => {
      const isCompletedCardClosed = currentUser?.user_profiles?.tutorial_cards?.some(
        (userCard) => userCard.card_key === card.key && !userCard.is_show
      )
      const key = 'card' + card.key
      const isCompletedInLocalStorage = isCompletedCardClosed
        ? true
        : localStorage.getItem(key) !== null

      const isCompletedBySetting = isCompletedInLocalStorage
        ? true
        : tutorialCard
        ? tutorialCard[card.key]
        : false

      if (isCompletedBySetting) {
        completedCount += 1
      }
    })
    return completedCount
  }

  useEffect(() => {
    if (!currentUser || !tutorialCard) return

    const handleStorageChange = () => {
      let completedCount = countStepsCompleted()
      setStepsCompleted(5 - completedCount)
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [currentUser, tutorialCard])

  useEffect(() => {
    if (!currentUser || !tutorialCard) return

    let completedCount = countStepsCompleted()
    setStepsCompleted(5 - completedCount)
  }, [currentUser, tutorialCard])

  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {currentUser && (
        <>
          <button
            type='button'
            className='btn d-flex align-items-center flex-column flex-sm-row btn-outline btn-sm fs-3 fw-boldest py-1 gori-teal-btn balance-button'
            onClick={() => routes.DEPOSITS.hasPermission && navigate(ROUTES.DEPOSITS.BASE)}
          >
            <span
              className={clsx('balance-company text-nowrap rounded-pill', {
                'm-0': !isFeatureEnabled(FEATURES.METER),
              })}
            >
              {currentAccount?.name}
            </span>
            <div>
              {isFeatureEnabled(FEATURES.METER) && (
                <div>
                  $
                  <CountUp
                    start={0}
                    end={meter?.balance}
                    separator=','
                    decimal={'.'}
                    decimals={2}
                    className='me-2'
                  />
                  <KTSVG path='/media/gori/topbar/wallet.svg' className='me-2 svg-icon-2' />
                </div>
              )}
            </div>
          </button>

          <button
            type='button'
            className='btn p-2 ms-2 position-relative'
            onClick={() => handleShowChecklist()}
            id='tutorial-checklist-toggle'
          >
            <KTSVG
              path='/media/gori/topbar/list-icon.svg'
              className='svg-icon-1 text-white position-relative'
              svgClassName='text-white'
            />
            <div
              className='d-inline-flex p-2 align-items-center justify-content-center'
              style={{
                borderRadius: '4px',
                background: 'var(--warning, #FF7272)',
                position: 'absolute',
                bottom: '0.3rem',
                right: '0.6rem',
                height: '1.2rem',
                width: '0.5rem',
              }}
            >
              <span
                className='m-0'
                style={{
                  color: 'var(--white, #FFF)',
                  textAlign: 'center',
                  fontFamily: 'Poppins',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                }}
              >
                {stepsCompleted}
              </span>
            </div>
          </button>
        </>
      )}

      {isFeatureEnabled(FEATURES.TUTORIAL) && showChecklist && (
        <TutorialChecklist closeChecklist={closeChecklist} />
      )}

      {!currentUser && (
        <Link
          to={ROUTES.AUTH.LOGIN}
          onClick={() => SwapperComponent.removeInstances()}
          className='d-flex flex-stack'
        >
          <button
            type='button'
            className='btn bg-white btn-outline text-primary btn-active-light-primary rounded-pill px-5 px-md-8'
          >
            {intl.formatMessage({id: 'LOG_IN'})}
          </button>
        </Link>
      )}
    </div>
  )
}

export {Topbar}
