import moment from 'moment'
import {TIMEZONES} from '../constants'
import {round} from './FormatHelpers'

const convertCurrency = (amount, currency = '$', includeDecimal = true) => {
  if (!amount || Number(amount) === 0) {
    return '-'
  }

  return (
    currency +
    '' +
    new Intl.NumberFormat('en', {
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    }).format(round(Number(amount)))
  )
}

const convertUserTimeZone = (datetime: string, currentUser: any, format: any) => {
  if (!datetime) {
    return ''
  }
  const timezoneConst: any = TIMEZONES.find(
    (tz) => tz.value === currentUser?.user_profiles?.general?.timezone?.value
  )
  let convertedDate = moment(datetime).add(timezoneConst?.offset * 60, 'minutes')
  if (currentUser?.user_profiles?.general?.timezone?.dst) {
    convertedDate.add(60, 'minutes')
  }
  return convertedDate.format(format)
}

function convertUnit(
  fromUnit: {value: number; unit: string},
  toUnit: {value?: number; unit: string}
) {
  const conversionTable = {
    in: {cm: 2.54, mm: 25.4},
    cm: {in: 0.393701, mm: 10},
    mm: {in: 0.0393701, cm: 0.1},
    oz: {lb: 0.0625, gm: 28.3495, kg: 0.0283495},
    lb: {oz: 16, gm: 453.592, kg: 0.453592},
    gm: {oz: 0.035274, lb: 0.00220462, kg: 0.001},
    kg: {oz: 35.274, lb: 2.20462, gm: 1000},
  }

  if (fromUnit.unit === toUnit.unit) {
    return fromUnit.value
  } else if (conversionTable[fromUnit.unit] && conversionTable[fromUnit.unit][toUnit.unit]) {
    const conversionRate = conversionTable[fromUnit.unit][toUnit.unit]
    return fromUnit.value * conversionRate
  } else {
    return 0
  }
}

export {convertCurrency, convertUserTimeZone, convertUnit}
