import clsx from 'clsx'
import {findIndex, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {TIMEZONES, FEATURES, ROUTES} from '../../../../../../_gori/constants'
import {convertCurrency, isFeatureEnabled} from '../../../../../../_gori/helpers'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {TableWrapper} from '../../../../../../_gori/partials/widgets'
import displayConfig from '../../../../../../displayconfig.json'
import {useAuth} from '../../../../auth'
import {
  ClaimAlertModal,
  LowBalanceAlertModal,
  TimeZoneModal,
  LanguageModal,
} from '../../../../settings'
import {ConfigGeneral} from '../../../core/_const'
import SettingsService from '../../../core/_requests'
import UseRoutesPermission from '../../../../../../_gori/hooks/UseRoutesPermission'
import {LanguageConfig} from '../../../../../../_gori/config/LanguageConfig'
import {useLanguage} from '../../../../../../_gori/i18n/Metronici18n'

const GeneralPage: React.FC = () => {
  const intl = useIntl()
  const {routes} = UseRoutesPermission()
  const {newCancelToken, isCancel} = useCancelToken()
  const {currentUser} = useAuth()
  const {language} = useLanguage()
  const [searchParams] = useSearchParams()
  const [showModal, setShowModal] = useState<any>({
    language: false,
    time_zone: false,
    low_balance_alerts: false,
    claim_alerts: false,
  })
  const [dataNotification, setDataNotification] = useState<{meter: any; claims: any}>({
    meter: undefined,
    claims: undefined,
  })
  const [isLoading, setIsLoading] = useState(false)

  const nameTab = useMemo(() => {
    const tabInit =
      isFeatureEnabled(FEATURES.METER) || isFeatureEnabled(FEATURES.CLAIMS)
        ? ConfigGeneral.NAME_TAB.NOTIFICATIONS
        : ConfigGeneral.NAME_TAB.LANGUAGE
    return searchParams.get('type') ? searchParams.get('type') : tabInit
  }, [searchParams])

  const timeZone = useMemo(() => {
    let timezone = currentUser?.user_profiles?.general?.timezone?.value ?? null
    let key = findIndex(TIMEZONES, (e) => e.value === timezone)
    return TIMEZONES[key]?.label ?? null
  }, [currentUser?.user_profiles?.general?.timezone?.value])

  const currentLanguage = useMemo(() => {
    return LanguageConfig().LANGUAGES.find((x) => x.value === language) ?? null
  }, [language])

  const buildHtml = useCallback(
    (config) => {
      return (
        <div className='d-flex justify-content-center text-gray-700'>
          <div className='w-100 w-sm-75 border border-secondary rounded-2 py-6 px-8'>
            <div className='d-flex justify-content-between fw-bolder'>
              <span className='fs-3'>{intl.formatMessage({id: config.title})}</span>
              <span
                className='fs-5 text-primary text-decoration-underline cursor-pointer'
                onClick={() => setShowModal((prev) => ({...prev, [config.name]: true}))}
              >
                {intl.formatMessage({id: 'EDIT'})}
              </span>
            </div>
            {config?.subtitle && <div className='fw-bold text-gray-500'>{config?.subtitle}</div>}
            <div className='row fs-5'>{config.children}</div>
          </div>
        </div>
      )
    },
    [intl]
  )

  const buildSkeleton = useMemo(() => {
    return (
      <div className='d-flex justify-content-center'>
        <div className='w-100 w-sm-75 bg-light rounded p-8'>
          <div className='d-flex justify-content-between'>
            <div className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
            <span className='fs-1 col-1 placeholder rounded-2 bg-secondary' />
          </div>
          <div className='fs-2 col-12 placeholder placeholder-lg rounded-2 bg-secondary mt-6 mb-10' />
          <div className='col-12 d-flex justify-content-between'>
            <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
            <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
          <div className='col-12 d-flex justify-content-between mt-6'>
            <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
            <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
        </div>
      </div>
    )
  }, [])

  const buildConfig = useMemo(() => {
    const dataMeter = dataNotification.meter
    const dataClaims = dataNotification.claims

    let _obj: any = {
      LANGUAGE: {
        title: 'PREFERRED_LANGUAGE',
        name: 'language',
        subtitle: intl.formatMessage({id: 'LANGUAGE_TEXT'}),
        children: (
          <div className='row fs-5 mt-8'>
            <div className='col-6 col-sm-4 fw-bold'>{intl.formatMessage({id: 'LANGUAGE'})}</div>
            <div className='col-6 col-sm-8 fw-bolder'>{currentLanguage?.label}</div>
          </div>
        ),
      },
      TIME_ZONE: {
        title: 'TIME_ZONE',
        name: 'time_zone',
        subtitle: intl.formatMessage({id: 'TIME_ZONE_TEXT'}),
        children: (
          <div className='row fs-5 mt-8'>
            <div className='col-6 col-sm-4 fw-bold'>{intl.formatMessage({id: 'TIME_ZONE'})}</div>
            <div className='col-6 col-sm-8 fw-bolder'>{timeZone}</div>
          </div>
        ),
      },
    }

    if (isFeatureEnabled(FEATURES.METER) && routes.NOTIFICATIONS_METER.hasPermission) {
      _obj = {
        ..._obj,
        LOW_BALANCE_ALERTS: {
          title: 'LOW_BALANCE_ALERTS',
          name: 'low_balance_alerts',
          subtitle: intl.formatMessage(
            {id: 'LOW_BALANCE_TEXT'},
            {brand: intl.formatMessage({id: 'BRAND_NAME'})}
          ),
          children: (
            <>
              {dataMeter?.status ? (
                <>
                  <div className='row fs-5 mt-8'>
                    <div className='col-6 col-sm-4 fw-bold'>
                      {intl.formatMessage({id: 'THRESHOLD'})}
                    </div>
                    <div className='col-6 col-sm-8 fw-bolder'>
                      {convertCurrency(dataMeter.value.threshold)}
                    </div>
                  </div>
                  <div className='row fs-5 mt-8'>
                    <div className='col-6 col-sm-4 fw-bold'>
                      {intl.formatMessage({id: 'EMAIL_TO'})}
                    </div>
                    <div className='col-6 col-sm-8 fw-bolder'>
                      {dataMeter?.receiver?.email?.map((email, index) => (
                        <div
                          className={clsx(index === 0 ? 'text-truncate' : 'text-truncate mt-2')}
                          key={index}
                        >
                          {email}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className='row fs-5 mt-8'>
                  <div className='col-6 col-sm-4 fw-bold'>{intl.formatMessage({id: 'ALERT'})}</div>
                  <div className='col-6 col-sm-8 fw-bolder'>
                    {intl.formatMessage({id: dataMeter?.status ? 'ON' : 'OFF'}).toUpperCase()}
                  </div>
                </div>
              )}
            </>
          ),
        },
      }
    }

    if (isFeatureEnabled(FEATURES.CLAIMS) && routes.NOTIFICATIONS_CLAIMS.hasPermission) {
      _obj = {
        ..._obj,
        CLAIM_ALERTS: {
          title: 'CLAIM_ALERTS',
          name: 'claim_alerts',
          children: (
            <div className='row fs-5 mt-8'>
              {dataClaims?.status ? (
                <>
                  <div className='col-6 col-sm-4 fw-bold'>
                    {intl.formatMessage({id: 'EMAIL_TO'})}
                  </div>
                  <div className='col-6 col-sm-8 fw-bolder'>
                    {dataClaims?.receiver?.email?.map((email, index) => (
                      <div
                        className={clsx(index === 0 ? 'text-truncate' : 'text-truncate mt-2')}
                        key={index}
                      >
                        {email}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className='col-6 col-sm-4 fw-bold'>{intl.formatMessage({id: 'ALERT'})}</div>
                  <div className='col-6 col-sm-8 fw-bolder'>
                    {intl.formatMessage({id: 'OFF'}).toUpperCase()}
                  </div>
                </>
              )}
            </div>
          ),
        },
      }
    }
    return _obj
  }, [currentLanguage, dataNotification, intl, routes, timeZone])

  const getDataNotification = useCallback(
    async (name?: string) => {
      try {
        const config = {
          params: {
            type: name,
          },
          cancelToken: newCancelToken(),
        }
        setIsLoading(true)
        const {notifications} = await SettingsService.getNotification(config)

        const data = isEmpty(notifications)
          ? {}
          : notifications.reduce((total, current: any) => {
              total[current?.category] = current
              return total
            }, {})

        setDataNotification(data)
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setIsLoading(false)
      }
    },
    [isCancel, newCancelToken]
  )

  useEffect(() => {
    getDataNotification()
  }, [getDataNotification])

  return (
    <>
      {showModal.language && (
        <LanguageModal
          show={showModal.language}
          handleClose={() => {
            setShowModal((prev) => ({...prev, language: false}))
          }}
        />
      )}
      {showModal.time_zone && (
        <TimeZoneModal
          show={showModal.time_zone}
          handleClose={() => {
            setShowModal((prev) => ({...prev, time_zone: false}))
          }}
        />
      )}
      {showModal.low_balance_alerts && (
        <LowBalanceAlertModal
          show={showModal.low_balance_alerts}
          handleClose={() => {
            setShowModal((prev) => ({...prev, low_balance_alerts: false}))
          }}
          handleReload={() => getDataNotification()}
          data={dataNotification.meter}
        />
      )}
      {showModal.claim_alerts && (
        <ClaimAlertModal
          show={showModal.claim_alerts}
          handleClose={() => {
            setShowModal((prev) => ({...prev, claim_alerts: false}))
          }}
          handleReload={() => getDataNotification()}
          data={dataNotification.claims}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableWrapper className='h-100'>
          <div className='mb-4'>
            <div className='card-header card-header-stretch d-flex h-55px'>
              <div className='card-title'>
                <h3>{intl.formatMessage({id: 'GENERAL'})}</h3>
              </div>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {(isFeatureEnabled(FEATURES.METER) || isFeatureEnabled(FEATURES.CLAIMS)) && (
                  <li className='nav-item'>
                    <Link
                      to={`${ROUTES.SETTINGS.GENERAL}?type=${ConfigGeneral.NAME_TAB.NOTIFICATIONS}`}
                      className={clsx('nav-link text-active-primary me-6 ', {
                        active: nameTab === ConfigGeneral.NAME_TAB.NOTIFICATIONS,
                      })}
                    >
                      {intl.formatMessage({id: 'NOTIFICATIONS'})}
                    </Link>
                  </li>
                )}
                <li className='nav-item'>
                  <Link
                    to={`/settings/general?type=${ConfigGeneral.NAME_TAB.LANGUAGE}`}
                    className={clsx('nav-link text-active-primary me-6 ', {
                      active: nameTab === ConfigGeneral.NAME_TAB.LANGUAGE,
                    })}
                  >
                    {intl.formatMessage({id: 'LANGUAGE'})}
                  </Link>
                </li>
                {displayConfig.items.settings.timezone && (
                  <li className='nav-item'>
                    <Link
                      to={`/settings/general?type=${ConfigGeneral.NAME_TAB.TIME_ZONE}`}
                      className={clsx('nav-link text-active-primary me-6 ', {
                        active: nameTab === ConfigGeneral.NAME_TAB.TIME_ZONE,
                      })}
                    >
                      {intl.formatMessage({id: 'TIME_ZONE'})}
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            <div className='card-body'>
              {isLoading
                ? Object.entries(buildConfig).map(([key, config], index) => {
                    return (
                      <div className='mt-8' key={index}>
                        {buildSkeleton}
                      </div>
                    )
                  })
                : Object.entries(buildConfig).map(([key, config]) => {
                    switch (key) {
                      case 'LANGUAGE':
                        return (
                          nameTab === ConfigGeneral.NAME_TAB.LANGUAGE && (
                            <div className='mt-8'>{buildHtml(config)}</div>
                          )
                        )
                      case 'TIME_ZONE':
                        return (
                          nameTab === ConfigGeneral.NAME_TAB.TIME_ZONE && (
                            <div className='mt-8'>{buildHtml(config)}</div>
                          )
                        )
                      default:
                        return (
                          nameTab === ConfigGeneral.NAME_TAB.NOTIFICATIONS && (
                            <div className='mt-8'>{buildHtml(config)}</div>
                          )
                        )
                    }
                  })}
            </div>
          </div>
        </TableWrapper>
      </CSSTransition>
    </>
  )
}

export {GeneralPage}
