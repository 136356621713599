import {useNavigate} from 'react-router-dom'
import {useAuth} from './core/Auth'
import {useEffect} from 'react'
import {ROUTES} from '../../../_gori/constants/_routes'

export function Logout() {
  const navigate = useNavigate()
  const {logout} = useAuth()
  logout()

  useEffect(() => {
    navigate(ROUTES.AUTH.LOGIN)
  }, [navigate])
  return null
}
