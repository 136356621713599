import React, {useContext, useState} from 'react'
import {A1BatchesProviderModel} from '../../app/modules/a1/core/_models'
import {useSearchParams} from 'react-router-dom'

const initA1BatchContextPropsState = {
  a1Batch: null,
  setA1Batch: () => {},
  ticketForum: {showList: false, detailId: null},
  setTicketForum: () => {},
}

const A1BatchContext = React.createContext<A1BatchesProviderModel>(initA1BatchContextPropsState)

const A1BatchesProvider: React.FC = ({children}) => {
  const [searchParams] = useSearchParams()
  const [a1Batch, setA1Batch] = useState<any>(initA1BatchContextPropsState.a1Batch)
  const [ticketForum, setTicketForum] = useState<{showList: boolean; detailId: string | null}>(
    searchParams?.get('modal') !== null
      ? {showList: searchParams?.get('modal') === 'true', detailId: searchParams?.get('id')}
      : initA1BatchContextPropsState.ticketForum
  )

  return (
    <A1BatchContext.Provider
      value={{
        a1Batch,
        setA1Batch,
        ticketForum,
        setTicketForum,
      }}
    >
      {children}
    </A1BatchContext.Provider>
  )
}

function useA1BatchesProvider() {
  return useContext(A1BatchContext)
}

export {A1BatchesProvider, useA1BatchesProvider}
