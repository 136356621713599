import {useCallback, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {RevealSlider} from './components/RevealSlider'
import AuthService from '../../modules/auth/core/_requests'
import {StartFreeToday} from './components/StartFreeToday'
import {Discounts} from './components/Discounts'
import {PublicWrapper} from '../../modules/common/components/PublicWrapper'
import {ROUTES} from '../../../_gori/constants'

const baseDomain = process.env.REACT_APP_DOMAIN

export function LandingPage() {
  const intl = useIntl()

  const animatedCardsRef = useRef<HTMLUListElement>(null)
  const dashboardGraphicRef = useRef<HTMLDivElement>(null)
  const toolsGraphicRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const protocol = document.location.protocol
    const host = document.location.host?.split(':')?.[0]
    const domain = baseDomain?.split(':')?.[0]
    const subDomain = host.split(`.${domain}`).filter((item) => item !== domain)?.[0]
    if (subDomain) {
      let urlRedirect = `${protocol}//${baseDomain}/landing`
      document.location.replace(urlRedirect)
    }
  }, [])

  const getVersion = useCallback(async () => {
    try {
      await AuthService.getVersion()
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getVersion()
  }, [getVersion])

  // Animations / Graphics
  const flourishCards = useCallback(async () => {
    const cards = animatedCardsRef.current

    function handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && cards) {
          cards.classList.add('flourish')
        } else if (!entry.isIntersecting && cards) {
          cards.classList.remove('flourish')
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '-50% 0px -50% 0px',
      threshold: 0,
    })

    if (cards) {
      observer.observe(cards)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const dashboardPopIn = useCallback(async () => {
    const dashboard = dashboardGraphicRef.current

    function handleEntryToView(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && dashboard) {
          dashboard.classList.add('fade-in')
        }
      })
    }

    const observer = new IntersectionObserver(handleEntryToView, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    })

    if (dashboard) {
      observer.observe(dashboard)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const toolsPopIn = useCallback(async () => {
    const tools = toolsGraphicRef.current

    function handleEntryToView(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && tools) {
          tools.classList.add('fade-in')
        }
      })
    }

    const observer = new IntersectionObserver(handleEntryToView, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    })

    if (tools) {
      observer.observe(tools)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    flourishCards()
    dashboardPopIn()
    toolsPopIn()
  }, [flourishCards, dashboardPopIn, toolsPopIn])

  return (
    <>
      <PublicWrapper>
        <div className='landing-page bg-white'>
          <section className='landing-hero gradient-bg'>
            <div className='container mb-25'>
              <h1
                className='raleway custom-h1 text-center text-primary mb-4'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'LANDING_H1'}),
                }}
              ></h1>
              <p
                className='poppins text-center text-gray-700 fw-bold mb-12'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'LANDING_SUBTITLE'}),
                }}
              ></p>

              <Discounts />

              <Link
                to={ROUTES.AUTH.REGISTRATION}
                className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content mx-auto mb-12px'
              >
                <img
                  className='me-3'
                  src={toAbsoluteUrl('/media/gori/landing/store.svg')}
                  alt='store-icon'
                />
                {intl.formatMessage({id: 'LANDING_BUTTON_START_FREE'})}
              </Link>

              <p className='fs-4 text-gray-700 text-center mx-auto mb-0'>
                <img src={toAbsoluteUrl('/media/gori/landing/check_circle_gray.svg')} alt='check' />{' '}
                {intl.formatMessage({id: 'LANDING_FOOTNOTE'})}
              </p>
            </div>

            <RevealSlider />

            <div className='container'>
              <div className=' hero-banner d-flex flex-column flex-md-row justify-content-center align-items-center border border-primary rounded-3 mt-4'>
                <div className='d-flex flex-column justify-content-center align-items-left w-100'>
                  <h4 className='raleway text-primary text-capitalize'>
                    {intl.formatMessage({id: 'HERO_BANNER_HEADING'})}
                  </h4>
                  <p className='pretendard mb-0'>{intl.formatMessage({id: 'HERO_BANNER_TEXT'})}</p>
                </div>

                <div>
                  <Link
                    to={ROUTES.AUTH.REGISTRATION}
                    className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block mx-auto mb-12px'
                  >
                    <img
                      className='me-3'
                      src={toAbsoluteUrl('/media/gori/landing/store.svg')}
                      alt='store-icon'
                    />
                    {intl.formatMessage({id: 'HERO_BANNER_BUTTON_TEXT'})}
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className='features'>
            <div className='container'>
              <h2 className='mb-30 fw-boldest text-center lh-sm custom-h2 light'>
                {intl.formatMessage({id: 'LANDING_H2_1_1'})}
                <br />
                <span>{intl.formatMessage({id: 'LANDING_H2_1_2'})}</span>
              </h2>

              <ul
                className='list-unstyled animated-cards mb-30'
                id='animated-cards'
                ref={animatedCardsRef}
              >
                <li className='card one'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_1'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_1'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/dashboard-graphic.webp')}
                    alt='dashboard graphic'
                  />
                </li>
                <li className='card two'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_2'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_2'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/oms-graphic.webp')}
                    alt='oms graphic'
                  />
                </li>
                <li className='card three'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_3'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_3'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/label-graphic.webp')}
                    alt='label graphic'
                  />
                </li>
                <li className='card four'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_4'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_4'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/claims-graphic.webp')}
                    alt='claims graphic'
                  />
                </li>
                <li className='card five'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_5'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_5'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/reports-graphic.webp')}
                    alt='reports graphic'
                  />
                </li>
              </ul>
            </div>
          </section>

          <section className='whats-new overflow-x-hidden'>
            <div className='container d-flex align-items-center justify-content-between'>
              <div className='copy-container'>
                <h2
                  className='mb-6 fw-boldest raleway custom-h2 bigger pt-5'
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({id: 'LANDING_H2_4'}),
                  }}
                ></h2>
                <h3 className='raleway custom-h3 mb-12'>
                  {intl.formatMessage({id: 'LANDING_SUBTITLE_2'})}
                </h3>
                <a
                  href='https://gori.ai/shipbae-update'
                  target='_blank'
                  rel='noreferrer'
                  className='desktop-only pretendard btn btn-outline custom-rocket-btn rounded-pill text-center d-block w-fit-content'
                >
                  {intl.formatMessage({id: 'LANDING_BUTTON_2'})}
                </a>
              </div>
              <div className='device-container'>
                <div className='device tablet'>
                  <img
                    className='deviceBorder'
                    src='https://gori-ai.s3.us-west-2.amazonaws.com/shipbae-assets/tablet.webp'
                    alt=''
                  />
                  <video
                    src='https://gori-ai.s3.us-west-2.amazonaws.com/shipbae-assets/shipbae-update.mov'
                    muted
                    loop
                    autoPlay
                  ></video>
                </div>
              </div>
              <a
                href='https://gori.ai/shipbae-update'
                target='_blank'
                rel='noreferrer'
                className='mobile-only mt-15 pretendard btn btn-outline custom-rocket-btn rounded-pill text-center d-block w-fit-content'
              >
                {intl.formatMessage({id: 'LANDING_BUTTON_2'})}
              </a>
            </div>
          </section>

          <StartFreeToday />
        </div>
      </PublicWrapper>
    </>
  )
}
