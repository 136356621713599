import React from 'react'
import {PRICE_TIER_COMPARISON, PRICE_TIERS} from '../core/_const'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {useIntl} from 'react-intl'
import {ROUTES} from '../../../../_gori/constants'

type Props = {
  page: string
  onClick?: any
  customClass?: string
}

const ComparePlanFeatures: React.FC<Props> = ({page, onClick, customClass}) => {
  const intl = useIntl()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN
  const registrationURL = document.location.protocol + '//' + BASE_DOMAIN + ROUTES.AUTH.REGISTRATION
  const additionalClass = customClass ? customClass : 'justify-content-center'

  return (
    <section className='tier-comparison container'>
      <div className='accordion open' id='pricingTiersAccordion'>
        <div className='accordion-item'>
          <h4 className='accordion-header' id='headingId'>
            <button
              className={'roboto accordion-button fs-3 fw-boldest ' + additionalClass}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseId'
              aria-expanded='true'
              aria-controls='collapseId'
            >
              {intl.formatMessage({id: 'TIER_COMPARISON_HEADING'})}
            </button>
          </h4>
          <div
            id='collapseId'
            className='accordion-collapse collapse show'
            aria-labelledby='headingId'
            data-bs-parent='#pricingTiersAccordion'
          >
            <div className='accordion-body pt-0'>
              <ul className='price-comparison-list list-unstyled'>
                <li className='header-row d-none d-md-flex flex-row justify-content-between px-3 pb-3 pt-8'>
                  <div></div>
                  {PRICE_TIERS.map((tier, index) => (
                    <div
                      key={tier.tier_name + index}
                      className='d-flex flex-column justify-content-center align-items-center '
                    >
                      <h5 className='roboto fs-3 fw-bold lh-lg mb-0'>
                        {intl.formatMessage({id: tier.tier_name})}
                      </h5>
                      {!tier.negotiable && (
                        <p className='pricing roboto fs-1 fw-bolder text-center mb-3'>
                          ${tier.price}
                          <small className='text-body-secondary'>/mo</small>
                        </p>
                      )}
                      <a
                        href={tier.negotiable ? 'mailto:sales@goricompany.com' : registrationURL}
                        className='btn btn-primary btn-darker pretendard fw-boldest fs-4 fs-lg-2 lh-lg rounded-pill mt-auto mb-4'
                        onClick={(e) => onClick(e, tier.openModal)}
                      >
                        {intl.formatMessage({
                          id: tier.negotiable ? 'LINK_TO_SALES_LABEL' : 'GET_STARTED',
                        })}
                      </a>
                    </div>
                  ))}
                </li>
                {PRICE_TIER_COMPARISON.map((feature, index) => (
                  <li
                    key={feature.name + index}
                    className='d-flex flex-column flex-md-row justify-content-between p-3'
                  >
                    <div>
                      <h5 className='roboto fs-3 lh-lg fw-boldest mb-0'>
                        {intl.formatMessage({id: feature.name})}
                      </h5>
                    </div>
                    <div className='d-flex justify-content-start align-items-center d-md-none'>
                      <p className='roboto text-primary darker-primary fs-2 lh-md fw-boldest mb-0'>
                        {intl.formatMessage({id: 'STARTING_AT_TIER'})}
                        {` `}
                        {intl.formatMessage({id: feature.tier_name})}
                      </p>
                    </div>
                    <div className='d-none d-md-flex text-center justify-content-center align-items-center'>
                      {feature.lowest_tier_available === 1 &&
                        !feature.unlimited &&
                        !feature.number_of_users && (
                          <img
                            src={toAbsoluteUrl('/media/gori/landing/teal-check.svg')}
                            alt='check'
                          />
                        )}
                      {feature.unlimited && !feature.number_of_users && (
                        <p className='roboto text-primary darker-primary fs-2 lh-md fw-boldest mb-0'>
                          {intl.formatMessage({id: 'UNLIMITED'})}
                        </p>
                      )}
                      {feature.number_of_users && (
                        <p className='roboto text-primary darker-primary fs-2 lh-md fw-boldest mb-0'>
                          {feature.number_of_users}
                        </p>
                      )}
                    </div>
                    <div className='d-none d-md-flex text-center justify-content-center align-items-center'>
                      {feature.lowest_tier_available <= 2 && !feature.unlimited && (
                        <img
                          src={toAbsoluteUrl('/media/gori/landing/teal-check.svg')}
                          alt='check'
                        />
                      )}
                      {feature.unlimited && (
                        <p className='roboto text-primary darker-primary fs-2 lh-md fw-boldest mb-0'>
                          {intl.formatMessage({id: 'UNLIMITED'})}
                        </p>
                      )}
                    </div>
                    <div className='d-none d-md-flex text-center justify-content-center align-items-center'>
                      {feature.lowest_tier_available <= 3 && !feature.unlimited && (
                        <img
                          src={toAbsoluteUrl('/media/gori/landing/teal-check.svg')}
                          alt='check'
                        />
                      )}
                      {feature.unlimited && (
                        <p className='roboto text-primary darker-primary fs-2 lh-md fw-boldest mb-0'>
                          {intl.formatMessage({id: 'UNLIMITED'})}
                        </p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {ComparePlanFeatures}
