const ROUTES: any = {
  LANDING: {
    BASE: '/landing',
  },
  PRICING: {
    BASE: '/pricing',
  },
  PRODUCT: {
    BASE: '/product',
  },
  INTEGRATIONS: {
    BASE: '/integrations',
    AMAZON: '/integrations/amazon',
    SHIPSTATION: '/integrations/shipstation',
    SHOPIFY: '/integrations/shopify',
    WALMART: '/integrations/walmart',
    EBAY: '/integrations/ebay',
    TIKTOK: '/integrations/tiktok',
  },
  AUTH: {
    BASE: '/auth',
    ONBOARDING: '/auth/onboarding',
    LOGIN: '/auth/login',
    RETOOL: '/retool/login',
    LOGOUT: '/auth/logout',
    FORGOT_PASSWORD: '/auth/forgot-password',
    NEW_PASSWORD: '/auth/new-password',
    REGISTRATION: '/auth/registration',
    ACTIVE_ACCOUNT: '/auth/active-account',
    PENDING_ACTIVATION: '/auth/pending-activation',
    ACTIVE_USER: '/auth/active-user',
  },
  DASHBOARD: {
    BASE: '/dashboard',
  },
  ORDERS: {
    BASE: '/orders',
    CREATE: '/orders/create',
  },
  SHIPMENTS: {
    BASE: '/shipments',
  },
  END_OF_DAY: {
    BASE: '/end-of-day',
    OPEN_SHIPMENTS: '/end-of-day/open-shipments',
    CLOSED_SHIPMENTS: '/end-of-day/closed-shipments',
  },
  BATCHES: {
    BASE: '/batches',
  },
  A1: {
    BASE: '/a1-batches',
  },
  CLAIMS: {
    BASE: '/claims',
    POLICY: '/claims/policy',
  },
  REPORTS: {
    BASE: '/reports',
    SHIPMENT: '/reports/shipments',
    RETURN_SHIPMENT: '/reports/return-shipments',
    TRANSACTIONS: '/reports/transactions',
    TRANSACTIONS_ENDING_BALANCE: '/reports/transactions-ending-balance',
    ORDER: '/reports/orders',
    DATA: '/reports/data',
  },
  DEPOSITS: {
    BASE: '/deposits',
  },
  SETTINGS: {
    BASE: '/settings',
    PROFILE: '/settings/profile',
    GENERAL: '/settings/general',
    USER_MANAGEMENT: '/settings/user-management',
    API_KEYS: '/settings/api-keys',
    SHIPPING_ADDRESSES: '/settings/shipping-addresses',
    PACKAGES: '/settings/packages',
    PRESETS: '/settings/presets',
    LABELS_SLIPS: '/settings/labels-slips',
    CONNECTIONS: {
      BASE: '/settings/connections',
      SHIPSTATION: '/settings/connections/shipstation',
      SHOPIFY: '/settings/connections/shopify',
      SHOPIFY_VERIFY: '/settings/connections/shopify/verify',
      WALMART: '/settings/connections/walmart',
      EBAY: '/settings/connections/ebay',
      TIKTOK: '/settings/connections/tiktok',
      TIKTOK_VERIFY: '/settings/connections/tiktok/verify',
    },
  },
  SUPPORT: {
    BASE: '/support',
  },
  MAINTENANCE: {
    BASE: '/maintenance',
  },
  SERVICE_TERMS: {
    BASE: '/service-terms',
    MNDA: '/service-terms/mnda',
  },
  PRIVACY_POLICY: {
    BASE: '/privacy-policy',
  },
  HELP_CENTER: {
    BASE: '/help-center',
  },
  ERROR: {
    BASE: '/error',
    ERROR404: '/error/404',
    ERROR500: '/error/500',
  },
  GLOBAL: {
    BASE: '/global',
    SHOPIFY: '/global/shopify/verify',
    TIKTOK: '/global/tiktok/verify',
    EBAY: '/global/ebay/verify',
  },
}

const generateRelativePaths = (section: any) => {
  return Object.fromEntries(
    Object.entries(section)
      .filter(
        ([key, value]) => typeof value === 'string' && !['RELATIVE', 'ABSOLUTE'].includes(key)
      )
      .map(([key, value]: any) => [key, value.replace(`${section.BASE}/`, '') + '/*'])
  )
}

const generateAbsolutePaths = (section: any) => {
  return Object.fromEntries(
    Object.entries(section)
      .filter(
        ([key, value]) =>
          typeof value === 'string' && !['RELATIVE', 'ABSOLUTE', 'BASE'].includes(key)
      )
      .map(([key, value]: any) => [key, value.replace(`${section.BASE}/`, '')])
  )
}

const addPathsRecursively = (section: any, visited = new WeakSet()) => {
  if (typeof section !== 'object' || section === null) return
  if (visited.has(section)) return
  visited.add(section)

  // Generate RELATIVE and ABSOLUTE for the current section if it has a BASE
  if ('BASE' in section && typeof section.BASE === 'string') {
    section.RELATIVE = generateRelativePaths(section)
    section.ABSOLUTE = generateAbsolutePaths(section)
  }

  // Process nested objects
  for (const [key, value] of Object.entries(section) as [string, any][]) {
    // Skip processing the newly added RELATIVE and ABSOLUTE properties
    if (key === 'RELATIVE' || key === 'ABSOLUTE') continue

    if (typeof value === 'object' && value !== null) {
      addPathsRecursively(value, visited)

      // Add reference to nested section with BASE in parent's RELATIVE and ABSOLUTE
      if ('BASE' in value && typeof value.BASE === 'string') {
        // Extract the relative path from the nested BASE
        const relativePath = value.BASE.replace(`${section.BASE}/`, '')

        // Initialize RELATIVE and ABSOLUTE objects if they don't exist
        if (!section.RELATIVE) section.RELATIVE = {}
        if (!section.ABSOLUTE) section.ABSOLUTE = {}

        // Add entry for the nested section
        section.RELATIVE[key] = `${relativePath}/*`
        section.ABSOLUTE[key] = relativePath
      }
    }
  }
}

Object.entries(ROUTES).forEach(([key, section]: any) => {
  addPathsRecursively(section)
})
export {ROUTES}
