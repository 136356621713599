import clsx from 'clsx'
import {find} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import CountUp from 'react-countup'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {isFeatureEnabled, KTSVG} from '../../../../../_gori/helpers'
import {useMeter} from '../../../../../_gori/helpers/components/MeterProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../../_gori/hooks/UseRoutesPermission'
import {Button, InputCheckNoFormik} from '../../../../../_gori/partials/widgets'
import SettingsService from '../../../settings/core/_requests'
import {ConfigGeneral} from '../../../settings/core/_const'
import {FEATURES} from '../../../../../_gori/constants/_features'
import {ROUTES} from '../../../../../_gori/constants'

type Props = {
  loading?: boolean
  onLoading: any
}

const Wallet: React.FC<Props> = ({loading, onLoading}) => {
  const intl = useIntl()
  const {meter} = useMeter()
  const {newCancelToken, isCancel} = useCancelToken()
  const navigate = useNavigate()
  const {routes} = UseRoutesPermission()
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [loadingCheck, setLoadingCheck] = useState<boolean>(false)
  const [dataMeter, setDataMeter] = useState<any>()

  const handleEnableRecharge = async () => {
    try {
      setLoadingCheck(true)
      const config = {cancelToken: newCancelToken()}
      const payload = {
        category: dataMeter.category,
        type: dataMeter.type,
        status: !dataMeter.status,
      }
      const res = await SettingsService.updateNotification(dataMeter?.id, payload, config)
      if (res) {
        setDataMeter((prev) => ({...prev, status: !prev.status}))
        toast.success(intl.formatMessage({id: res.message}))
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setLoadingCheck(false)
    }
  }

  const getDataMeter = useCallback(async () => {
    try {
      setLoadingCheck(true)
      const config = {
        params: {
          category: ConfigGeneral.NOTIFICATION_CATEGORY.METER,
        },
        cancelToken: newCancelToken(),
      }
      const {notifications} = await SettingsService.getNotification(config)

      if (notifications) {
        setDataMeter(find(notifications, {category: ConfigGeneral.NOTIFICATION_CATEGORY.METER}))
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoadingCheck(false)
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    getDataMeter()
  }, [getDataMeter])

  const popover = (
    <Popover
      className='mw-500px'
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
    >
      <Popover.Header as='h3' className='bold fw-bolder'>
        {intl.formatMessage({id: 'LOW_BALANCE_ALERT'})}
      </Popover.Header>
      <Popover.Body className='fw-bolder text-muted'>
        {intl.formatMessage(
          {id: 'LOW_BALANCE_POPUP_TEXT'},
          {
            brand: intl.formatMessage({id: 'BRAND_NAME'}),
            threshold: <span className='text-info'>${dataMeter?.value?.threshold || 0}</span>,
          }
        )}{' '}
        {intl.formatMessage(
          {id: 'LOW_BALANCE_POPUP_TEXT_ADDITION'},
          {text: <span className='text-info'>{intl.formatMessage({id: 'EDIT'})}</span>}
        )}
        <div className='d-flex justify-content-end mt-4'>
          <Button
            className='btn btn-sm btn-primary fw-bolder'
            label={intl.formatMessage({id: 'EDIT'})}
            loadingText={intl.formatMessage({id: 'EDIT'})}
            event={() =>
              navigate(`${ROUTES.SETTINGS.GENERAL}?type=${ConfigGeneral.NAME_TAB.NOTIFICATIONS}`)
            }
          />
          <Button
            className='btn btn-sm btn-secondary fw-bolder ms-3'
            label={intl.formatMessage({id: 'CLOSE'})}
            event={() => setShowPopover(false)}
          />
        </div>
      </Popover.Body>
    </Popover>
  )

  useEffect(() => {
    onLoading('wallet')
  }, [meter, onLoading])

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      {loading ? (
        <div className='card shadow-sm h-210'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h1 className='col-4 placeholder-wave'>
                <span className='col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
              </h1>
              <div className='col-8 d-flex flex-row-reverse'>
                <div className='d-flex placeholder-wave'>
                  <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary' />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between align-items-center mt-12'>
              <div className='col-7 placeholder-wave'>
                <span className='btn col-11 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-3 placeholder-wave'>
                <span className='btn col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={clsx('dashboard__wallet card shadow-sm h-210', {
            'red-border':
              Number(dataMeter?.value?.threshold) !== 0 &&
              Number(meter?.balance) < Number(dataMeter?.value?.threshold),
          })}
        >
          <div className='card-body d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <div className='text-dark fw-bolder fs-3 opacity-75'>
                {intl.formatMessage({id: 'WALLET'})}
              </div>
              {routes.ADMINS.hasPermission && routes.NOTIFICATIONS_METER.hasPermission && (
                <OverlayTrigger
                  trigger={['click', 'hover']}
                  show={showPopover}
                  placement='auto'
                  overlay={popover}
                  delay={300}
                >
                  <div
                    className='d-flex align-items-center'
                    onMouseEnter={() => setShowPopover(true)}
                    onMouseLeave={() => setShowPopover(false)}
                  >
                    <div className='d-flex flex-center'>
                      <div className='px-2 pb-1'>
                        <KTSVG path='/media/gori/dashboard/notification.svg' small={false} />
                      </div>
                      <InputCheckNoFormik
                        type='switch'
                        checked={dataMeter?.status}
                        onChange={() => handleEnableRecharge()}
                        disabled={loadingCheck}
                      />
                    </div>
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <div className='d-flex justify-content-between align-items-center mt-12'>
              <div className='dashboard-text-color'>
                <span className='fw-bolder fs-3 align-middle opacity-75 me-1'>$</span>
                <span className='align-middle fw-bolder opacity-75 fs-1'>
                  <CountUp
                    start={0}
                    end={meter?.balance}
                    separator=','
                    decimal={'.'}
                    decimals={2}
                  />
                </span>
              </div>
              {isFeatureEnabled(FEATURES.DEPOSITS) && routes.DEPOSITS.hasPermission && (
                <div className='dashboard__wallet__add-fund'>
                  <button
                    type='button'
                    className='btn btn-outline rounded-pill btn-sm gori-teal-btn mt-3'
                    onClick={() => navigate(ROUTES.DEPOSITS.BASE)}
                  >
                    {intl.formatMessage({id: 'ADD_FUND'})}
                  </button>
                </div>
              )}
            </div>
            {Number(dataMeter?.value?.threshold) !== 0 &&
              Number(meter?.balance) < Number(dataMeter?.value?.threshold) && (
                <div className='d-flex pb-3 align-items-center'>
                  <KTSVG path='/media/gori/dashboard/error_balance.svg' />
                  <div className='text-muted fw-bold ms-2'>
                    {intl.formatMessage({id: 'YOUR_ACCOUNT_BALANCE_IS_GETTING_LOW'})}
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </CSSTransition>
  )
}

export {Wallet}
