/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy, Suspense} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_gori/assets/ts/_utils'
import {DisableSidebar} from '../../_gori/layout/core'
import {MasterLayoutForNoAuth} from '../../_gori/layout/MasterLayoutForNoAuth'
import {App} from '../App'
import {AuthPage, Logout, useAuth} from '../modules/auth'
import {MaintenancePage} from '../modules/errors'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {MNDA} from '../modules/agreements'
import GlobalWrapper from '../pages/global/GlobalWrapper'
import {LandingPage} from '../pages/landing/LandingPage'
import {ProductPage} from '../pages/landing/ProductPage'
import {PricingPage} from '../pages/landing/PricingPage'
import {PrivacyPolicyPage} from '../pages/landing/PrivacyPolicyPage'
import {ServiceTermsPage} from '../pages/landing/ServiceTermsPage'
import OnboardWrapper from '../pages/onboard/OnboardWrapper'
import {PrivateRoutes} from './PrivateRoutes'
import {RetoolLogin} from '../pages/login/RetoolLogin'
import {AmazonIntegration} from '../pages/integrations/AmazonIntegration'
import {ShipStationIntegration} from '../pages/integrations/ShipStationIntegration'
import {ShopifyIntegration} from '../pages/integrations/ShopifyIntegration'
import {WalmartIntegration} from '../pages/integrations/WalmartIntegration'
import {EBayIntegration} from '../pages/integrations/EBayIntegration'
import {TikTokIntegration} from '../pages/integrations/TikTokIntegration'
import {isFeatureEnabled} from '../../_gori/helpers'
import {FEATURES, ROUTES} from '../../_gori/constants'

/**
 * Base URL of the website.
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const SupportWrapper = lazy(() => import('../pages/support/SupportWrapper'))
  const HelpCenterPageWrapper = lazy(() => import('../pages/help-center/HelpCenterWrapper'))

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <>
            {isFeatureEnabled(FEATURES.LANDING_PAGE) && (
              <Route path={ROUTES.LANDING.RELATIVE.BASE} element={<LandingPage />} />
            )}
            {isFeatureEnabled(FEATURES.PRICING_PAGE) && (
              <Route path={ROUTES.PRICING.RELATIVE.BASE} element={<PricingPage />} />
            )}
            {isFeatureEnabled(FEATURES.PRODUCT_PAGE) && (
              <Route path={ROUTES.PRODUCT.RELATIVE.BASE} element={<ProductPage />} />
            )}
            {isFeatureEnabled(FEATURES.MARKETPLACE) && (
              <>
                <Route path={ROUTES.INTEGRATIONS.AMAZON} element={<AmazonIntegration />} />
                <Route
                  path={ROUTES.INTEGRATIONS.SHIPSTATION}
                  element={<ShipStationIntegration />}
                />
                <Route path={ROUTES.INTEGRATIONS.SHOPIFY} element={<ShopifyIntegration />} />
                <Route path={ROUTES.INTEGRATIONS.WALMART} element={<WalmartIntegration />} />
                <Route path={ROUTES.INTEGRATIONS.EBAY} element={<EBayIntegration />} />
                <Route path={ROUTES.INTEGRATIONS.TIKTOK} element={<TikTokIntegration />} />
              </>
            )}
            {isFeatureEnabled(FEATURES.SERVICE_TERMS) && (
              <Route path={ROUTES.SERVICE_TERMS.RELATIVE.BASE} element={<ServiceTermsPage />} />
            )}
            {isFeatureEnabled(FEATURES.PRIVACY_POLICY) && (
              <Route path={ROUTES.PRIVACY_POLICY.RELATIVE.BASE} element={<PrivacyPolicyPage />} />
            )}
            {isFeatureEnabled(FEATURES.HELP_CENTER) && (
              <Route path={ROUTES.HELP_CENTER.RELATIVE.BASE} element={<HelpCenterPageWrapper />} />
            )}
          </>
          <>
            <Route path={ROUTES.ERROR.RELATIVE.BASE} element={<ErrorsPage />} />
            <Route path={ROUTES.GLOBAL.RELATIVE.BASE} element={<GlobalWrapper />} />
            <Route path={ROUTES.MAINTENANCE.BASE} element={<MaintenancePage />} />
            <Route path={ROUTES.AUTH.RETOOL} element={<RetoolLogin />} />
            <Route path={ROUTES.AUTH.LOGOUT} element={<Logout />} />
            {currentUser ? (
              <>
                {currentUser.onboard_step !== 'done' && (
                  <>
                    <Route path={ROUTES.AUTH.ONBOARDING} element={<OnboardWrapper />} />
                    <Route path={ROUTES.SERVICE_TERMS.MNDA} element={<MNDA />} />
                    <Route path='*' element={<Navigate to={ROUTES.AUTH.ONBOARDING} />} />
                  </>
                )}
                {currentUser.onboard_step === 'done' && (
                  <Route path='/*' element={<PrivateRoutes />} />
                )}
              </>
            ) : (
              <>
                <Route path={ROUTES.AUTH.RELATIVE.BASE} element={<AuthPage />} />
                <Route element={<MasterLayoutForNoAuth />}>
                  {isFeatureEnabled(FEATURES.SUPPORT_PAGE) && (
                    <Route
                      path={ROUTES.SUPPORT.RELATIVE.BASE}
                      element={
                        <SuspenseView>
                          <SupportWrapper />
                        </SuspenseView>
                      }
                    />
                  )}
                  <Route
                    path='*'
                    element={
                      isFeatureEnabled(FEATURES.LANDING_PAGE) ? (
                        <Navigate to={ROUTES.LANDING.BASE} />
                      ) : (
                        <Navigate to={ROUTES.AUTH.BASE} />
                      )
                    }
                  />
                </Route>
              </>
            )}
          </>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const SuspenseView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {AppRoutes, SuspenseView}
