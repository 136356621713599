import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {Button} from '../form/Button'
import {useLocation, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'

type Props = {
  basePath?: any
  className?: any
  event?: any
  label?: any
}

const ButtonBack: FC<Props> = ({basePath = '/', className, event, label}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const handleBack = () => {
    const {pathname} = location
    const [basePathWithoutQuery, baseQuery] = basePath.split('?')

    // Get relative path by removing basePathWithoutQuery only if pathname starts with it
    const currentPath = pathname.startsWith(basePathWithoutQuery)
      ? pathname.slice(basePathWithoutQuery.length).replace(/^\/|\/$/g, '')
      : pathname.replace(/^\/|\/$/g, '')

    if (!currentPath) {
      navigate(basePath) // If already at base, stay there
      return
    }

    const parentPath = currentPath.split('/').slice(0, -1).join('/')
    const finalPath = parentPath ? `${basePathWithoutQuery}/${parentPath}` : basePathWithoutQuery

    // Reattach the base query if it exists
    navigate(baseQuery ? `${finalPath}?${baseQuery}` : finalPath)
  }

  return (
    <Button
      label={label ?? intl.formatMessage({id: 'BACK'})}
      event={event ?? handleBack}
      className={`${className} btn btn-light btn-sm fw-bolder me-5`}
      children={<KTSVG path='/media/gori/arrows/arrow_left.svg' />}
    />
  )
}

export {ButtonBack}
