import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {Button} from '../../../../_gori/partials/widgets'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../../_gori/constants'

type Props = {
  show: boolean
  handleClose: () => void
  configModal: {
    title: string
  }
}

const NoticePrintPackingSlips: React.FC<Props> = ({show, handleClose, configModal}) => {
  const intl = useIntl()

  const handleCloseModal = () => {
    handleClose()
  }

  return (
    <Modal
      id='gori_modal_notice_print_packing_slips'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-800px h-auto'
      show={show}
      backdrop='static'
      onHide={() => {
        handleClose()
      }}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: configModal?.title || ''})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mx-10 my-5 d-flex flex-column justify-content-center align-items-center fs-4'>
            <div className='mb-5'>
              <img src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')} alt='img' />
            </div>
            <p>
              {intl.formatMessage({
                id: 'YOUR_PACKING_SLIP_HAS_BEEN_DISABLED_PLEASE_SET_YOUR_PACKING_SLIP_UNDER',
              })}{' '}
              <Link to={ROUTES.SETTINGS.LABELS_SLIPS}>
                {intl.formatMessage({
                  id: 'SETTINGS_LABELS_PACKING_SLIPS_PACKING_SLIP_CONTENT',
                })}
              </Link>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-2'
              label={intl.formatMessage({id: 'CANCEL'})}
              loadingText={intl.formatMessage({id: 'CANCEL'})}
              event={handleCloseModal}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {NoticePrintPackingSlips}
