import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import SettingsService from '../../../../core/_requests'
import {Button, ButtonBack, InputTextFormik} from '../../../../../../../_gori/partials/widgets'
import {StoresListing} from '../StoresListing'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {OrderType} from '../../../../../orders/core/_const'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import {ROUTES} from '../../../../../../../_gori/constants'

const ConnectShopify: React.FC = () => {
  const intl = useIntl()
  const {regexYup} = UseYupValidate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {isCancel} = useCancelToken()

  const validationSchema = Yup.object().shape({
    storeLink: regexYup.storeLink,
  })

  const initialValues = {
    storeLink: '',
  }

  const onSubmit = async (values, {setStatus}) => {
    setIsLoading(true)
    await SettingsService.getShopifyIntegrateLink(values.storeLink)
      .then((res) => {
        window.location.replace(res)
      })
      .catch((error) => {
        if (isCancel(error)) return
        setStatus(error?.response?.data?.error?.message ?? '')
      })
      .finally(() => setIsLoading(false))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='card p-10 rounded-bottom-0'>
          <div className='card-title'>
            <div className='d-flex gap-4 align-items-center'>
              <ButtonBack basePath={ROUTES.SETTINGS.CONNECTIONS.BASE} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} Shopify
              </span>
            </div>
          </div>
          <div className='card-body d-flex justify-content-center row'>
            <div className='d-flex align-items-start col-9'>
              <div className='col-9 d-flex align-items-center'>
                <InputTextFormik
                  className='col-md-9'
                  labelClassName='col-md-3'
                  required
                  label={'Shopify ' + intl.formatMessage({id: 'STORE_DOMAIN'})}
                  formik={formik}
                  name={'storeLink'}
                />
              </div>
              <div
                className={clsx('btn-group ms-5', {
                  'cursor-no-drop': isLoading,
                })}
              >
                <Button
                  className={clsx('btn btn-primary', {
                    'pe-none': isLoading,
                    'cursor-no-drop': isLoading,
                  })}
                  loading={isLoading}
                  loadingText={intl.formatMessage({id: 'CONNECT'})}
                  label={intl.formatMessage({id: 'CONNECT'})}
                  event={formik.submitForm}
                />
              </div>
            </div>
            {formik.status && (
              <div className='d-flex align-items-start col-9'>
                <div className='col-9 d-flex align-items-center'>
                  <div className='col-md-3'></div>
                  <div className='col-md-9 mt-5 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
      <StoresListing storeType={OrderType.SHOPIFY} />
    </>
  )
}

export {ConnectShopify}
