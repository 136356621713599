import {FC} from 'react'
import {DisableSidebar} from '../../../_gori/layout/core'
import {OnboardPage} from '../../modules/onboard'

const OnboardWrapper: FC = () => {
  return (
    <DisableSidebar>
      <OnboardPage />
    </DisableSidebar>
  )
}

export default OnboardWrapper
