import clsx from 'clsx'
import React from 'react'
import {useSearchParams} from 'react-router-dom'
import {useA1BatchesProvider} from '../../../../../../_gori/providers/A1BatchesProvider'
import {DEFAULT_PAGE} from '../../../../../../_gori/constants'
import A1_CONSTANTS from '../../../core/_const'
import {TicketDetail, TicketList} from '../../../index'

type Props = {
  show: boolean
  handleClose: () => void
}

const TicketForumModal: React.FC<Props> = ({show, handleClose}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const {ticketForum, setTicketForum} = useA1BatchesProvider()

  const handleCloseModal = () => {
    searchParams.set('page', DEFAULT_PAGE)
    A1_CONSTANTS.PARAMS_TICKET_FILTER.forEach((param) => searchParams.delete(param))
    setSearchParams(searchParams)
    setTicketForum((prev) => ({...prev, detailId: null}))
    handleClose()
  }

  const handleBack = () => {
    searchParams.delete('id')
    setSearchParams(searchParams)
    setTicketForum((prev) => ({...prev, detailId: null}))
  }

  return (
    <>
      <div className={clsx('ticket-forum', {show})}>
        {ticketForum?.detailId ? (
          <TicketDetail handleBack={handleBack} handleCloseModal={handleCloseModal} />
        ) : (
          <TicketList show={show} handleCloseModal={handleCloseModal} />
        )}
      </div>
      <div className='ticket-forum-overlay'></div>
    </>
  )
}

export {TicketForumModal}
