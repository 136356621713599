import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {FORMAT_DATE} from '../../../../../../../_gori/constants'
import {convertUserTimeZone, KTSVG} from '../../../../../../../_gori/helpers'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import {Button, ValidationErrorModal} from '../../../../../../../_gori/partials/widgets'
import {useA1BatchesProvider} from '../../../../../../../_gori/providers/A1BatchesProvider'
import {useAuth} from '../../../../../auth'
import A1BatchService from '../../../../core/_requests'
import {CommentTicket} from './CommentTicket'
import A1_CONSTANTS from '../../../../core/_const'
import {useSearchParams} from 'react-router-dom'

type Props = {
  handleCloseModal: () => void
  handleBack: () => void
}

const TicketDetail: React.FC<Props> = ({handleBack, handleCloseModal}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const {a1Batch, ticketForum} = useA1BatchesProvider()
  const [ticket, setTicket] =
    useState<{id: number; name: string; status: string; comments: any; created_at: string}>()
  const [loading, setLoading] = useState<{
    getTicket: boolean
    refresh: boolean
    send: boolean
    attachments: boolean
  }>({
    getTicket: false,
    refresh: false,
    send: false,
    attachments: false,
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const [validationErrors, setValidationErrors] = useState<any>()
  const lastCommentRef = useRef<HTMLDivElement | null>(null)

  const ticketDate = useMemo(() => {
    return ticket?.created_at
      ? convertUserTimeZone(ticket?.created_at, currentUser, FORMAT_DATE.DATE_TIME)
      : 'DD/MM/YYYY | hh:mm'
  }, [currentUser, ticket])

  const getTicketDetail = useCallback(
    async ({refresh = false}) => {
      if (refresh) {
        lastCommentRef.current = null
      }

      try {
        setLoading((prev) => ({...prev, [refresh ? 'refresh' : 'getTicket']: true}))
        const response = await A1BatchService.getTicketDetail(a1Batch?.id, ticketForum?.detailId, {
          cancelToken: newCancelToken(),
        })
        setTicket(response.a1_ticket)
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
        searchParams.delete('id')
        setSearchParams(searchParams)
      } finally {
        setLoading((prev) => ({...prev, [refresh ? 'refresh' : 'getTicket']: false}))
      }
    },
    [a1Batch?.id, isCancel, newCancelToken, ticketForum?.detailId, searchParams, setSearchParams]
  )

  useEffect(() => {
    if ((!loading.getTicket || !loading.refresh) && lastCommentRef.current) {
      lastCommentRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [loading.getTicket, loading.refresh, ticket])

  useEffect(() => {
    getTicketDetail({refresh: false})
  }, [getTicketDetail])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
            handleBack()
          }}
          response={validationErrors}
        />
      )}
      <div>
        <div className='ticket-forum-title d-flex justify-content-between align-items-center border-bottom border-gray-300 p-8'>
          <div className='h-40px d-flex align-items-center'>
            <div className='cursor-pointer' onClick={handleBack}>
              <KTSVG
                path='/media/gori/arrows/arrowLeft2.svg'
                className='svg-icon-1 text-dark text-hover-primary'
              />
            </div>
          </div>
          <div className='cursor-pointer' onClick={handleCloseModal}>
            <KTSVG
              path='/media/gori/orders/delete.svg'
              className='svg-icon-4 text-gray-700 text-hover-dark'
            />
          </div>
        </div>
        <div className='ticket-forum-body d-flex flex-column gap-4 p-8'>
          {loading.getTicket || isEmpty(ticket) ? (
            <div className='h-100 d-flex flex-column border border-gray-300 rounded-2'>
              <div className='d-flex justify-content-between align-items-center p-4 border-bottom border-gray-300'>
                <div className='col-10 d-flex flex-column'>
                  <div className='fs-1 col-6 placeholder rounded-2 bg-secondary' />
                  <span className='fs-1 col-4 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
                </div>
                <span className='fs-1 col-2 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
              </div>
              <div className='scroll-y flex-fill'>
                {Array.from({length: 3}).map((_, index) => (
                  <div className='bg-light rounded-2 p-4 m-4' key={index}>
                    <div className='fs-1 col-5 placeholder rounded-2 bg-secondary' />
                    <div className='fs-4 col-9 placeholder rounded-2 bg-secondary mt-2' />
                  </div>
                ))}
              </div>
              <div className='border-top border-gray-300 p-6'>
                <div className='position-relative'>
                  <textarea className='form-control py-4 ps-4 pe-30 resize-none h-50px pe-none' />
                  <div className='d-flex position-absolute top-50 end-0 translate-middle-y'>
                    {['attachments', 'send'].map((icon, idx) => (
                      <div key={idx} className='mx-4'>
                        <KTSVG
                          path={`/media/gori/a1/${icon}.svg`}
                          className='svg-icon-1 text-dark'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='h-100 d-flex flex-column border border-gray-300 rounded-2'>
              <div className='d-flex justify-content-between align-items-center p-4 border-bottom border-gray-300'>
                <div>
                  <h4 className='text-break me-4'>
                    {intl.formatMessage({id: 'TICKET'})} #{ticket?.id} - {ticket?.name}
                  </h4>
                  <span className='text-muted'>
                    {`${intl.formatMessage({id: 'CREATED_ON'})} ${ticketDate}`}
                  </span>
                </div>
                <div className='d-flex gap-4'>
                  <Button
                    className={clsx(
                      'd-flex align-items-center justify-content-center btn btn-sm p-0 text-hover-primary cursor-pointer',
                      {
                        'text-primary': loading.refresh,
                      }
                    )}
                    event={() => getTicketDetail({refresh: true})}
                  >
                    <KTSVG
                      path='/media/gori/a1/sync.svg'
                      className={clsx('svg-icon-1 text-hover-primary', {
                        'spin_reverse text-primary': loading.refresh,
                      })}
                    />
                    {intl.formatMessage({id: 'REFRESH'})}
                  </Button>
                  <span className={`badge-${ticket?.status}-ticket rounded-pill px-2`}>
                    {intl.formatMessage({id: ticket?.status.toUpperCase()})}
                  </span>
                </div>
              </div>
              <div className='scroll-y flex-fill'>
                {loading?.refresh
                  ? Array.from({length: 3}).map((_, index) => (
                      <div className='bg-light rounded-2 p-4 m-4' key={index}>
                        <div className='fs-1 col-5 placeholder rounded-2 bg-secondary' />
                        <div className='fs-4 col-9 placeholder rounded-2 bg-secondary mt-2' />
                      </div>
                    ))
                  : ticket?.comments.map((comment, index) => {
                      const isFirst = index === 0
                      const isLast = index === ticket?.comments.length - 1

                      return (
                        <div
                          className={clsx('rounded-2 p-4 m-4', {
                            'bg-light': !isFirst,
                            'bg-secondary': isFirst,
                          })}
                          key={index}
                          ref={isLast ? lastCommentRef : null}
                        >
                          {comment?.content &&
                            (isFirst ? (
                              <div
                                className='mt-3 fs-5 text-break text-pre-wrap'
                                dangerouslySetInnerHTML={{__html: comment?.content}}
                              />
                            ) : (
                              <>
                                <span>
                                  <strong className='fs-5 me-2'>
                                    {intl.formatMessage({id: comment.created_by?.toUpperCase()})}
                                  </strong>
                                  {convertUserTimeZone(
                                    comment.created_at,
                                    currentUser,
                                    FORMAT_DATE.DATE_TIME
                                  )}
                                </span>
                                <div className='mt-3 fs-5 text-break text-pre-wrap'>
                                  {comment?.content}
                                </div>
                              </>
                            ))}
                          <div>
                            {!comment?.content && (
                              <span>
                                <strong className='fs-5 me-2'>
                                  {intl.formatMessage({id: comment.created_by?.toUpperCase()})}
                                </strong>
                                {convertUserTimeZone(
                                  comment.created_at,
                                  currentUser,
                                  FORMAT_DATE.DATE_TIME
                                )}
                              </span>
                            )}
                            {comment?.attachments?.map((attachment, idx) => (
                              <div key={idx} className='my-4'>
                                <a
                                  className='d-inline-flex align-items-center bg-secondary p-2 rounded-2 text-gray-800 text-hover-dark'
                                  href={attachment?.file_path}
                                  download={attachment?.file_name}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <span className='me-4'>
                                    <KTSVG
                                      path='/media/gori/a1/file.svg'
                                      className='svg-icon-3 text-dark'
                                    />
                                  </span>
                                  <span className='mw-500px text-truncate'>
                                    {attachment?.file_name}
                                  </span>
                                  <KTSVG
                                    path='/media/gori/a1/fileDownload.svg'
                                    className='svg-icon-3 ms-2'
                                  />
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    })}
              </div>
              {ticket?.status === A1_CONSTANTS.TICKET_STATUS.OPEN.value && (
                <CommentTicket reloadTicketDetail={() => getTicketDetail({refresh: true})} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {TicketDetail}
