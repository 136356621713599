import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {allowTestOnly} from '../../../helpers'
import {SwitchCompany} from '../../../helpers/components/SwitchCompany'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {ROUTES} from '../../../constants'

const AsideUserMenu: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {isMobileTablet, isWindows} = useWindowDimensions()

  return (
    <div className={'d-flex flex-column p-4'}>
      <div className='d-flex align-items-center'>
        <Link
          to={ROUTES.SETTINGS.PROFILE}
          className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1'
        >
          {currentUser?.first_name + ' ' + currentUser?.last_name}
        </Link>
      </div>
      <div className='my-2'>
        {allowTestOnly(currentUser) && (
          <span className='fs-7 fw-bold'>
            {intl.formatMessage({id: 'CURRENT_VERSION'})}: #{process.env.REACT_APP_COMMIT_ID}
          </span>
        )}
      </div>
      <SwitchCompany
        className='bg-white rounded-2'
        classNameMenu={clsx({'me-12': isMobileTablet, 'ms-12': isWindows})}
      />
      <div className='d-flex justify-content-start'>
        <Link
          className='text-decoration-underline text-dark text-hover-danger text-end mt-4 fs-8'
          to={ROUTES.AUTH.LOGOUT}
        >
          <span>{intl.formatMessage({id: 'SIGN_OUT'})}</span>
        </Link>
      </div>
    </div>
  )
}

export {AsideUserMenu}
