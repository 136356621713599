import {ROUTES} from './_routes'

export const KEY_PERMISSIONS = {
  SYSTEM_ADMIN: 'system admin',
  ADMIN: 'admin',
  DASHBOARD: 'dashboard',
  ORDERS: 'orders',
  ORDERS_VIEW: 'orders:view',
  ORDERS_CREATE: 'orders:create',
  ORDERS_UPDATE: 'orders:update',
  ORDERS_CANCEL: 'orders:cancel',
  ORDERS_HOLD: 'orders:hold',
  ORDERS_ASSIGN: 'orders:assign',
  BATCHES: 'batches',
  BATCHES_VIEW: 'batches:view',
  BATCHES_CREATE: 'batches:create',
  BATCHES_UPDATE: 'batches:update',
  BATCHES_DELETE: 'batches:delete',
  SHIPMENTS: 'shipments',
  SHIPMENTS_VIEW: 'shipments:view',
  SHIPMENTS_CREATE: 'shipments:create',
  SHIPMENTS_REFUND: 'shipments:refund',
  SHIPMENTS_RETURN: 'shipments:return',
  SHIPMENTS_PACKING_SLIP: 'shipments:packing_slip',
  CLAIMS: 'claims',
  CLAIMS_VIEW: 'claims:view',
  CLAIMS_CREATE: 'claims:create',
  CLAIMS_UPDATE: 'claims:update',
  CLAIMS_APPEAL: 'claims:appeal',
  CLAIMS_DELETE: 'claims:delete',
  REPORTS: 'reports',
  REPORTS_SHIPMENTS: 'reports:shipments',
  REPORTS_TRANSACTIONS: 'reports:transactions',
  REPORTS_ORDERS: 'reports:orders',
  REPORTS_DATA: 'reports:data',
  DEPOSITS: 'deposits',
  SETTINGS: 'settings',
  SETTINGS_ADDRESSES: 'settings:addresses',
  SETTINGS_PACKAGES: 'settings:packages',
  SETTINGS_PRESETS: 'settings:presets',
  SETTINGS_LABELS_SLIPS: 'settings:labels&slips',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_METER: 'notifications:meter',
  NOTIFICATIONS_CLAIMS: 'notifications:claims',
  USERS: 'users',
  USERS_VIEW: 'users:view',
  USERS_UPDATE: 'users:update',
  USERS_REMOVE: 'users:remove',
  USERS_INVITE: 'users:invite',
}

// route: First route that is goto if you have permission for it
// subRoutes: For highlighting the left menu
export const ROUTING_PERMISSIONS = {
  ORDERS_VIEW: {
    hasPermission: false,
    route: ROUTES.ORDERS.BASE,
    roles: [KEY_PERMISSIONS.ORDERS_VIEW],
  },
  ADMINS: {
    hasPermission: false,
    route: ROUTES.DASHBOARD.BASE,
    roles: [],
  },
  DASHBOARD: {
    hasPermission: false,
    route: ROUTES.DASHBOARD.BASE,
    roles: [KEY_PERMISSIONS.DASHBOARD],
  },
  ORDERS_CREATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.ORDERS_CREATE],
  },
  ORDERS_UPDATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.ORDERS_UPDATE],
  },
  ORDERS_CANCEL: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.ORDERS_CANCEL],
  },
  ORDERS_HOLD: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.ORDERS_HOLD],
  },
  ORDERS_ASSIGN: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.ORDERS_ASSIGN],
  },
  BATCHES_VIEW: {
    hasPermission: false,
    route: ROUTES.BATCHES.BASE,
    roles: [KEY_PERMISSIONS.BATCHES_VIEW],
  },
  BATCHES_CREATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.BATCHES_CREATE],
  },
  BATCHES_UPDATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.BATCHES_UPDATE],
  },
  BATCHES_DELETE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.BATCHES_DELETE],
  },
  SHIPMENTS_VIEW: {
    hasPermission: false,
    route: ROUTES.SHIPMENTS.BASE,
    roles: [KEY_PERMISSIONS.SHIPMENTS_VIEW],
  },
  SHIPMENTS_CREATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.SHIPMENTS_CREATE],
  },
  SHIPMENTS_REFUND: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.SHIPMENTS_REFUND],
  },
  SHIPMENTS_RETURN: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.SHIPMENTS_RETURN],
  },
  SHIPMENTS_PACKING_SLIP: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.SHIPMENTS_PACKING_SLIP],
  },
  CLAIMS_VIEW: {
    hasPermission: false,
    route: ROUTES.CLAIMS.BASE,
    roles: [KEY_PERMISSIONS.CLAIMS_VIEW],
  },
  CLAIMS_CREATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.CLAIMS_CREATE],
  },
  CLAIMS_UPDATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.CLAIMS_UPDATE],
  },
  CLAIMS_APPEAL: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.CLAIMS_APPEAL],
  },
  CLAIMS_DELETE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.CLAIMS_DELETE],
  },
  REPORTS_SHIPMENTS: {
    hasPermission: false,
    route: ROUTES.REPORTS.SHIPMENT,
    roles: [KEY_PERMISSIONS.REPORTS_SHIPMENTS],
  },
  REPORTS_TRANSACTIONS: {
    hasPermission: false,
    route: ROUTES.REPORTS.TRANSACTIONS,
    roles: [KEY_PERMISSIONS.REPORTS_TRANSACTIONS],
  },
  REPORTS_ORDERS: {
    hasPermission: false,
    route: ROUTES.REPORTS.ORDER,
    roles: [KEY_PERMISSIONS.REPORTS_ORDERS],
  },
  REPORTS_DATA: {
    hasPermission: false,
    route: ROUTES.REPORTS.DATA,
    roles: [KEY_PERMISSIONS.REPORTS_DATA],
  },
  DEPOSITS: {
    hasPermission: false,
    route: ROUTES.DEPOSITS.BASE,
    roles: [KEY_PERMISSIONS.DEPOSITS],
  },
  SETTINGS_ADDRESSES: {
    hasPermission: false,
    route: ROUTES.SETTINGS.SHIPPING_ADDRESSES,
    roles: [KEY_PERMISSIONS.SETTINGS_ADDRESSES],
  },
  SETTINGS_PACKAGES: {
    hasPermission: false,
    route: ROUTES.SETTINGS.PACKAGES,
    roles: [KEY_PERMISSIONS.SETTINGS_PACKAGES],
  },
  SETTINGS_PRESETS: {
    hasPermission: false,
    route: ROUTES.SETTINGS.PRESETS,
    roles: [KEY_PERMISSIONS.SETTINGS_PRESETS],
  },
  SETTINGS_LABELS_SLIPS: {
    hasPermission: false,
    route: ROUTES.SETTINGS.LABELS_SLIPS,
    roles: [KEY_PERMISSIONS.SETTINGS_LABELS_SLIPS],
  },
  NOTIFICATIONS_METER: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.NOTIFICATIONS_METER],
  },
  NOTIFICATIONS_CLAIMS: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.NOTIFICATIONS_CLAIMS],
  },
  USERS_VIEW: {
    hasPermission: false,
    route: ROUTES.SETTINGS.USERS,
    roles: [KEY_PERMISSIONS.USERS_VIEW],
  },
  USERS_UPDATE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.USERS_UPDATE],
  },
  USERS_REMOVE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.USERS_REMOVE],
  },
  USERS_INVITE: {
    hasPermission: false,
    roles: [KEY_PERMISSIONS.USERS_INVITE],
  },
  SUPPORTS: {
    hasPermission: true,
    route: ROUTES.SUPPORT.BASE,
  },
  SYSTEM_ADMIN: {
    hasPermission: false,
    route: '/admin',
    roles: [KEY_PERMISSIONS.SYSTEM_ADMIN],
  },
}
