import {useCallback, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {HowItWorks} from './components/HowItWorks'
import {StartFreeToday} from './components/StartFreeToday'
import {Security} from './components/Security'
import {PublicWrapper} from '../../modules/common/components/PublicWrapper'
import {ROUTES} from '../../../_gori/constants'

export function ProductPage() {
  const intl = useIntl()

  const dashboardGraphicRef = useRef<HTMLDivElement>(null)
  const toolsGraphicRef = useRef<HTMLDivElement>(null)

  const dashboardPopIn = useCallback(async () => {
    const dashboard = dashboardGraphicRef.current

    function handleEntryToView(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && dashboard) {
          dashboard.classList.add('fade-in')
        }
      })
    }

    const observer = new IntersectionObserver(handleEntryToView, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    })

    if (dashboard) {
      observer.observe(dashboard)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const toolsPopIn = useCallback(async () => {
    const tools = toolsGraphicRef.current

    function handleEntryToView(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && tools) {
          tools.classList.add('fade-in')
        }
      })
    }

    const observer = new IntersectionObserver(handleEntryToView, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    })

    if (tools) {
      observer.observe(tools)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    dashboardPopIn()
    toolsPopIn()
  }, [dashboardPopIn, toolsPopIn])

  return (
    <>
      <PublicWrapper>
        <div className='product-page-container'>
          <section className='hero pb-20'>
            <h1
              className='raleway custom-h1 text-center text-primary mb-4'
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({id: 'PRODUCT_H1'}),
              }}
            ></h1>
            <p
              className='poppins text-center text-gray-700 fw-bold mb-24'
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({id: 'PRODUCT_SUBTITLE'}),
              }}
            ></p>

            <div className='container why'>
              <ul className='list-unstyled cards'>
                <li className='card d-flex flex-column gap-10'>
                  <div className='d-flex justify-content-center align-items-center exception w-100'>
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/store-connections.webp')}
                      alt='reports graphic'
                    />

                    <div className='pulsing-animation'>
                      <div className='ring one'></div>
                      <div className='ring two'></div>
                      <div className='ring three'></div>
                      <div className='ring four'></div>
                      <div className='ring five'></div>
                      <div className='ring six'></div>
                      <p>{intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_ANIMATED'})}</p>
                    </div>

                    <div className='w-100 integrate-inner'>
                      <p className='title'>
                        <span>{intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLUE_1'})}</span>
                        <br />
                        {intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLACK_1'})}
                      </p>

                      <ul className='list-unstyled d-flex flex-row flex-wrap'>
                        <Link to={ROUTES.INTEGRATIONS.SHIPSTATION}>
                          <li className='icons'>
                            <img
                              className='cog'
                              src={toAbsoluteUrl('/media/gori/landing/cog.webp')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to={ROUTES.INTEGRATIONS.SHOPIFY}>
                          <li className='icons'>
                            <img
                              className='shopify'
                              src={toAbsoluteUrl('/media/gori/landing/shopify.webp')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to={ROUTES.INTEGRATIONS.WALMART}>
                          <li className='icons'>
                            <img
                              className='walmart'
                              src={toAbsoluteUrl('/media/gori/landing/walmart.webp')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to={ROUTES.INTEGRATIONS.EBAY}>
                          <li className='icons'>
                            <img
                              className='ebay'
                              src={toAbsoluteUrl('/media/gori/landing/ebay.svg')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to={ROUTES.INTEGRATIONS.TIKTOK}>
                          <li className='icons'>
                            <img
                              className='tiktok'
                              src={toAbsoluteUrl('/media/gori/landing/tiktok.svg')}
                              alt=''
                            />
                          </li>
                        </Link>
                      </ul>

                      <ul className='list-unstyled d-flex flex-row flex-wrap'>
                        <Link to={ROUTES.INTEGRATIONS.AMAZON}>
                          <li className='icons gray coming-soon'>
                            <img
                              className='coming-soon-img'
                              src={toAbsoluteUrl('/media/gori/landing/coming-soon.webp')}
                              alt=''
                            />

                            <img
                              className='amazon'
                              src={toAbsoluteUrl('/media/gori/landing/amazon.svg')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <li className='icons'>
                          <img
                            className='plus'
                            src={toAbsoluteUrl('/media/gori/landing/plus.svg')}
                            alt=''
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    <span className='d-block mb-2'>
                      {intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_COPY_1'})}
                    </span>
                    <span className='d-block mb-2'>
                      {intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_COPY_1a'})}
                    </span>
                    <Link
                      className='fw-bolder text-decoration-underline'
                      to={intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_LINK_1'})}
                    >
                      {intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_LINK_TEXT_1'})}
                    </Link>
                    !
                  </p>
                </li>
                <li className='card d-flex justify-content-center align-items-center'>
                  <img
                    className=''
                    src={toAbsoluteUrl('/media/gori/landing/rates.webp')}
                    alt='rates graphic'
                  />

                  <div className='w-100'>
                    <p className='title'>
                      <span>{intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLUE_2'})}</span>
                      <br />
                      {intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLACK_2'})}
                    </p>

                    <ul className='list-unstyled d-flex flex-row flex-wrap'>
                      <li className='icons longer w-100'>
                        <img
                          className='got'
                          src={toAbsoluteUrl('/media/gori/landing/got.webp')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='usps'
                          src={toAbsoluteUrl('/media/gori/landing/usps.webp')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='ups'
                          src={toAbsoluteUrl('/media/gori/landing/ups.webp')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='dhl'
                          src={toAbsoluteUrl('/media/gori/landing/dhl.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='fedex'
                          src={toAbsoluteUrl('/media/gori/landing/fedex.svg')}
                          alt=''
                        />
                      </li>
                    </ul>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_COPY_2'}),
                      }}
                    ></p>
                  </div>
                </li>
                <li className='card d-flex justify-content-center align-items-center'>
                  <div
                    className='d-flex flex-column justify-content-center align-items-center dashboard-graphic'
                    id='dashboard-graphic'
                    ref={dashboardGraphicRef}
                  >
                    <div className='d-flex flex-row justify-content-space-between align-items-center'>
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/gori/landing/wallet.webp')}
                        alt='wallet graphic'
                      />
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/gori/landing/paid.webp')}
                        alt='paid graphic'
                      />
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/gori/landing/trends.webp')}
                        alt='trends graphic'
                      />
                    </div>
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/map.webp')}
                      alt='map graphic'
                    />
                  </div>

                  <div className='w-100'>
                    <p className='title'>
                      <span>{intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLUE_3'})}</span>
                      <br />
                      {intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLACK_3'})}
                    </p>

                    <p>{intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_COPY_3'})}</p>
                  </div>
                </li>
                <li className='card d-flex justify-content-center align-items-center'>
                  <div
                    className='d-flex flex-row flex-wrap justify-content-center align-items-center tools-graphic'
                    id='tools-graphic'
                    ref={toolsGraphicRef}
                  >
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/filter.webp')}
                      alt='filter graphic'
                    />
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/batches.webp')}
                      alt='batches graphic'
                    />
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/transactions.webp')}
                      alt='transactions graphic'
                    />
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/claims.webp')}
                      alt='claims graphic'
                    />
                  </div>

                  <div className='w-100'>
                    <p className='title'>
                      <span>{intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLUE_4'})}</span>
                      <br />
                      {intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_BLACK_4'})}
                    </p>

                    <ul className='list-unstyled d-flex flex-row flex-wrap'>
                      <li className='icons'>
                        <img
                          className='filter-icon'
                          src={toAbsoluteUrl('/media/gori/landing/filter-icon.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='batch-icon'
                          src={toAbsoluteUrl('/media/gori/landing/batch-icon.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='download-icon'
                          src={toAbsoluteUrl('/media/gori/landing/download-icon.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='card-icon'
                          src={toAbsoluteUrl('/media/gori/landing/card-icon.svg')}
                          alt=''
                        />
                      </li>
                    </ul>

                    <p>{intl.formatMessage({id: 'PRODUCT_CARDS_TITLE_COPY_4'})}</p>
                  </div>
                </li>
              </ul>

              <Link
                to={ROUTES.AUTH.REGISTRATION}
                className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content mx-auto'
              >
                <img
                  className='me-3'
                  src={toAbsoluteUrl('/media/gori/landing/store.svg')}
                  alt='store-icon'
                />
                {intl.formatMessage({id: 'LANDING_BUTTON_START_FREE'})}
              </Link>
            </div>
          </section>

          <HowItWorks />

          <Security />

          <StartFreeToday />
        </div>
      </PublicWrapper>
    </>
  )
}
