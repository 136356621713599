import clsx from 'clsx'
import {getIn, useFormik} from 'formik'
import {cloneDeep} from 'lodash'
import React, {ChangeEvent, useCallback, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {fileHelper, KTSVG} from '../../../../../../../_gori/helpers'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {ValidationErrorModal} from '../../../../../../../_gori/partials/widgets'
import {useA1BatchesProvider} from '../../../../../../../_gori/providers/A1BatchesProvider'
import A1_CONSTANTS from '../../../../core/_const'
import A1BatchService from '../../../../core/_requests'

type Props = {
  reloadTicketDetail: () => void
  supportedFormat?: any
  size?: any
  multiple?: boolean
  maxRow?: number
  maxUpload?: number
}

const CommentTicket: React.FC<Props> = ({
  reloadTicketDetail,
  supportedFormat = Object.values(A1_CONSTANTS.UPLOAD_CONFIG.TICKET_SUPPORTED_FORMATS),
  size = {label: '8MB', value: A1_CONSTANTS.UPLOAD_CONFIG.SIZE},
  multiple = true,
  maxRow = 0,
  maxUpload = A1_CONSTANTS.UPLOAD_CONFIG.MAX_FILES,
}) => {
  const intl = useIntl()
  const {isCancel, newCancelToken} = useCancelToken()
  const {a1Batch, ticketForum} = useA1BatchesProvider()
  const {stringYup} = UseYupValidate()
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{
    send: boolean
    attachments: boolean
  }>({
    send: false,
    attachments: false,
  })
  const [fileInput, setFileInput] = useState<File[]>([])

  const formik = useFormik({
    initialValues: {feedback: '', attachments: []},
    validationSchema: Yup.object({
      feedback: stringYup(5000, 'FEEDBACK', false),
      attachments: Yup.array(),
    }),
    enableReinitialize: true,
    onSubmit: async () => {
      handleSendFeedback()
    },
  })
  const formikErrors = getIn(formik.errors, 'feedback')
  const formikTouched = getIn(formik.touched, 'feedback')
  const hasFeedbackError = formikTouched && formikErrors

  const fileType = useMemo(() => {
    return {
      label: supportedFormat.map((item) => item.label).join(', '),
      value: new Set(supportedFormat.map((item) => item.value)),
    }
  }, [supportedFormat])

  const setFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) return
      const files = multiple ? Array.from(e.target.files) : [e.target.files[0]]
      e.target.value = ''

      if (maxUpload && files.length + fileInput.length > maxUpload) {
        return toast.warning(
          intl.formatMessage({id: 'UPLOAD_UP_TO_INPUT_FILES'}, {input: maxUpload})
        )
      }

      const validFiles: File[] = []
      for (const file of files) {
        if (!fileType.value.has(file.type)) {
          toast.error(
            intl.formatMessage(
              {id: 'FILE_INPUT_HAS_INVALID_EXTENSION_ONLY_EXTENSION_ARE_ALLOWED'},
              {fileName: file.name, extension: fileType.label}
            )
          )
          continue
        }
        if (maxRow) {
          const {row} = await fileHelper.checkRowHaveData(file)
          if (row > maxRow) {
            toast.error(
              intl.formatMessage({id: 'CANNOT_UPLOAD_MORE_THAN_INPUT_ITEMS'}, {input: maxRow})
            )
            continue
          }
        }
        if (file.size > size.value) {
          toast.warning(
            intl.formatMessage(
              {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_INPUT'},
              {fileName: file.name, size: size.label}
            )
          )
          continue
        }
        validFiles.push(file)
      }

      if (validFiles.length > 0) {
        setFileInput((prev) => [...validFiles, ...prev])
      }
    },
    [fileInput, fileType, intl, maxRow, maxUpload, multiple, size]
  )

  const handleDelete = useCallback(
    (index: number) => {
      const updatedFiles = fileInput.filter((_, i) => i !== index)
      setFileInput(updatedFiles)
    },
    [fileInput]
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (formik.values.feedback.trim() && !loading?.send) {
        formik.handleSubmit()
      }
    }
  }

  const handleChangeTextEditor = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    formik.setFieldValue('feedback', event.target.value)
    autoResize(event.target)
  }

  const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`
    }
  }

  const handleSendFeedback = async () => {
    if (!ticketForum?.detailId) return

    try {
      setLoading((prev) => ({...prev, send: true}))
      const payload = new FormData()
      payload.append('content', formik.values.feedback.trim())
      const attachments = cloneDeep(fileInput)
      if (attachments.length > 0) {
        attachments.map((file) => {
          payload.append('attachments[]', file)
          return file
        })
      }
      const res: any = await A1BatchService.postComments(
        a1Batch?.id,
        ticketForum?.detailId,
        payload,
        {
          cancelToken: newCancelToken(),
        }
      )
      if (res) {
        toast.success(intl.formatMessage({id: res.message}))
        setFileInput([])
        formik.resetForm()
        autoResize(textareaRef.current as HTMLTextAreaElement)
        reloadTicketDetail()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, send: false}))
    }
  }

  const sendBtn = useMemo(() => {
    const isDisabled =
      hasFeedbackError || loading?.send || (!formik.values.feedback.trim() && fileInput.length <= 0)
    const isActive =
      !hasFeedbackError && !loading?.send && (formik.values.feedback.trim() || fileInput.length > 0)
    return {isDisabled, isActive}
  }, [fileInput.length, formik.values.feedback, hasFeedbackError, loading?.send])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <div className='mt-4 border-top border-gray-300 p-6'>
        {fileInput.length > 0 && (
          <div
            className={clsx(
              'scroll-x d-flex border border-bottom-0 rounded-top h-100px transition',
              {
                'border-gray-400': isFocused && !hasFeedbackError,
                'border-gray-300': !isFocused && !hasFeedbackError,
                'border-danger': hasFeedbackError,
              }
            )}
          >
            {fileInput.map((file, idx) => (
              <div
                key={idx}
                className={clsx('ms-4 mt-4 mb-4 position-relative', {
                  'me-4': idx === fileInput.length - 1,
                })}
              >
                {file?.type.includes('image') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className='h-75px min-w-75px w-75px object-fit-cover border rounded-2'
                  />
                ) : (
                  <div className='h-100 d-flex align-items-center min-w-200px mw-200px border border-gray-300 rounded-2 p-3 bg-light'>
                    <span className='me-4'>
                      <KTSVG path='/media/gori/a1/file.svg' className='svg-icon-1 text-dark' />
                    </span>
                    <span className='text-truncate-2'>{file.name}</span>
                  </div>
                )}
                <div
                  className='position-absolute top-0 end-0 bg-white px-1 rounded-2 cursor-pointer'
                  onClick={() => handleDelete(idx)}
                >
                  <KTSVG
                    path='/media/gori/orders/delete.svg'
                    className='m-0 text-dark text-hover-danger'
                    svgClassName='mh-10px'
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='position-relative'>
          <textarea
            autoComplete='off'
            rows={1}
            ref={textareaRef}
            placeholder={intl.formatMessage({id: 'TYPE_YOUR_FEEDBACK_HERE'})}
            {...formik.getFieldProps('feedback')}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              formik.setFieldTouched('feedback', true)
              setIsFocused(false)
            }}
            onKeyDown={handleKeyDown}
            onChange={handleChangeTextEditor}
            className={clsx(
              'form-control py-4 ps-4 pe-30 resize-none scroll-y scrollbar-width-none min-h-50px transition-none',
              {
                'is-invalid': hasFeedbackError,
                'border-top-0 rounded-top-0': fileInput.length > 0,
              }
            )}
          />
          <div className='d-flex position-absolute top-50 end-0 translate-middle-y'>
            <div
              className={clsx('cursor-pointer mx-4 position-relative', {
                'cursor-no-drop': hasFeedbackError,
                'text-hover-primary': !hasFeedbackError,
              })}
              onClick={() => fileInputRef.current?.click()}
            >
              <KTSVG path={'/media/gori/a1/attachments.svg'} className='svg-icon-1 text-dark' />
              <input
                type='file'
                ref={fileInputRef}
                onChange={setFile}
                className='d-none'
                multiple={multiple}
                accept={supportedFormat.map((item) => item.value).join(',')}
              />
            </div>
            <div
              className={clsx('cursor-pointer mx-4', {
                'cursor-no-drop': sendBtn.isDisabled,
                'text-hover-primary': sendBtn.isActive,
              })}
              onClick={() => sendBtn.isActive && formik.handleSubmit()}
            >
              <KTSVG path={'/media/gori/a1/send.svg'} className='svg-icon-1 text-dark' />
            </div>
          </div>
        </div>
        {hasFeedbackError && (
          <div className='fv-plugins-message-container mt-2'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {CommentTicket}
