import {FC} from 'react'
import {Route, Routes} from 'react-router-dom'
import {PageDisabledProvider} from '../../../_gori/helpers'
import {DisableSidebar} from '../../../_gori/layout/core'
import {ShopifyVerifyPage} from './ShopifyVerifyPage'
import {TikTokVerifyPage} from './TikTokVerifyPage'
import {EbayVerifyPage} from './EbayVerifyPage'
import {ROUTES} from '../../../_gori/constants'

const GlobalRoute: FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.GLOBAL.RELATIVE.SHOPIFY} element={<ShopifyVerifyPage />} />
      <Route path={ROUTES.GLOBAL.RELATIVE.TIKTOK} element={<TikTokVerifyPage />} />
      <Route path={ROUTES.GLOBAL.RELATIVE.EBAY} element={<EbayVerifyPage />} />
    </Routes>
  )
}

const GlobalWrapper: FC = () => {
  return (
    <DisableSidebar>
      <PageDisabledProvider>
        <GlobalRoute />
      </PageDisabledProvider>
    </DisableSidebar>
  )
}

export default GlobalWrapper
