import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../../_gori/constants'

export function StartFreeToday() {
  const intl = useIntl()
  return (
    <section className='enjoy light-blue-bg'>
      <div className='container'>
        <p className='text-center fw-bolder pb-4 mx-auto pretendard'>
          {intl.formatMessage({id: 'LANDING_CLOSING_COPY'})}
        </p>
        <img
          className='d-block mx-auto shipbae-logo mw-100'
          src={toAbsoluteUrl('/media/gori/landing/shipbae-logo.webp')}
          alt='shipbae-logo'
        />

        <Link
          to={ROUTES.AUTH.REGISTRATION}
          className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content mt-12 mx-auto mb-12px'
        >
          <img
            className='me-3'
            src={toAbsoluteUrl('/media/gori/landing/store.svg')}
            alt='store-icon'
          />
          {intl.formatMessage({id: 'LANDING_BUTTON_START_FREE'})}
        </Link>
      </div>
    </section>
  )
}
