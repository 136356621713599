import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {KTSVG, StorageHelpers, toAbsoluteUrl} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UserService from '../../users/core/_requests'
import {TUTORIAL_CARDS} from '../core/_const'
import {useAuth} from '../../auth'
import clsx from 'clsx'
import {Button} from '../../../../_gori/partials/widgets'

interface TutorialChecklistProps {
  closeChecklist?: () => void
}

const TutorialChecklist: React.FC<TutorialChecklistProps> = ({closeChecklist}) => {
  const intl = useIntl()
  const {loadingSwitch, currentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const [visibleTutorialCards, setVisibleTutorialCards] = useState(TUTORIAL_CARDS)
  const [tutorialCard, setTutorialCard] = useState<Record<string, boolean> | null>(null)
  const checklistRef = useRef<HTMLDivElement>(null)
  const getTutorialCard = useCallback(async () => {
    try {
      const settings = await UserService.getTutorialCard({cancelToken: newCancelToken()})
      setTutorialCard(settings)
    } catch (error) {
      if (!isCancel(error)) {
        console.error('Error fetching tutorial card:', error)
      }
    }
  }, [newCancelToken, isCancel])
  const [stepsCompleted, setStepsCompleted] = useState(1)

  useEffect(() => {
    if (!loadingSwitch) {
      getTutorialCard()
    }
  }, [getTutorialCard, loadingSwitch])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const toggleButton = document.querySelector('#tutorial-checklist-toggle')
      const isToggleButton = toggleButton?.contains(event.target as Node)
      if (isToggleButton) return

      if (checklistRef.current && !checklistRef.current.contains(event.target as Node)) {
        closeChecklist && closeChecklist()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [closeChecklist])

  const handleSkip = useCallback(
    async (cardKey: string) => {
      StorageHelpers.setItemLocalStorage('card' + cardKey, {hidden: true})
      window.dispatchEvent(new Event('storage'))

      try {
        // Mark the card as completed in the state
        setTutorialCard((prev) => {
          if (!prev) return null
          return {
            ...prev,
            [cardKey]: true,
          }
        })

        setVisibleTutorialCards((prev) =>
          prev.map((card) => (card.key === cardKey ? {...card, completed: true} : card))
        )

        await UserService.removeTutorialCard(
          {
            card_key: cardKey,
            is_show: false,
          },
          {cancelToken: newCancelToken()}
        )
      } catch (error: any) {
        if (isCancel(error)) return
        console.error('Error skipping tutorial card:', error)
      }
    },
    [isCancel, newCancelToken]
  )

  const filteredSteps = useMemo(() => {
    let firstIncompleteFound = false
    if (!tutorialCard) return []
    return visibleTutorialCards.map((card) => {
      // Check if the list item has been closed before
      const isCompletedCardClosed = currentUser?.user_profiles?.tutorial_cards?.some(
        (userCardOnboard: {card_key: string; is_show: any}) =>
          userCardOnboard.card_key === card.key && !userCardOnboard.is_show
      )
      // Check if list item is disabled in local storage
      const isCompletedInLocalStorage = isCompletedCardClosed
        ? true
        : StorageHelpers.getItemLocalStorage('card' + card.key)

      // Check if the list item is disabled in src/app/modules/orders/core/_const.ts
      const isCompletedBySetting = isCompletedInLocalStorage ? true : tutorialCard[card.key]

      const completed = !!isCompletedBySetting
      const current = !completed && !firstIncompleteFound
      if (current) firstIncompleteFound = true

      return {
        ...card,
        completed,
        current,
      }
    })
  }, [currentUser?.user_profiles?.tutorial_cards, tutorialCard, visibleTutorialCards])

  useEffect(() => {
    let stepsCompletedCount = 1

    filteredSteps.forEach((item) => {
      if (item.completed) {
        stepsCompletedCount = stepsCompletedCount + 1
      }
    })

    setStepsCompleted(stepsCompletedCount)
  }, [filteredSteps])

  const ProgressText = () => {
    return (
      <div className='me-3'>
        <p
          className='text-white fs-6 mb-0'
          style={{
            color: 'var(--10, #F6F8FC)',
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: '-0.264px',
          }}
        >
          {stepsCompleted == 6
            ? intl.formatMessage({id: 'ONBOARDING_DONE'})
            : `${stepsCompleted} ${intl.formatMessage({id: 'ONBOARDING_TOTAL_STEPS'})}`}
        </p>
      </div>
    )
  }

  const ProgressBar = () => {
    return (
      <div className='five-bar-progress d-flex flex-row flex-nowrap gap-1' style={{flex: 1}}>
        {[1, 2, 3, 4, 5].map((barIndex) => {
          let width: string
          if (barIndex < stepsCompleted) {
            width = '100%'
          } else if (barIndex === stepsCompleted) {
            width = '35%'
          } else {
            width = '0%'
          }

          return (
            <div className='progress w-100' key={barIndex}>
              <div
                className='progress-bar bg-orange'
                role='progressbar'
                style={{
                  width,
                  transitionDelay: `${barIndex * 600}ms`,
                  transitionTimingFunction: `linear`,
                }}
                aria-valuenow={parseInt(width, 10) || 0}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          )
        })}
      </div>
    )
  }

  const TutorialChecklistHeader = () => {
    return (
      <>
        {' '}
        {stepsCompleted === 1 && (
          <h5 className='mb-4 ms-6 m-0 text-white'>
            {intl.formatMessage({id: 'FIRST_STEP_HEADER'})}
          </h5>
        )}
        {stepsCompleted > 1 && stepsCompleted < 6 && (
          <h5 className='mb-4 ms-6 m-0 text-white'>
            {intl.formatMessage({id: 'MID_STEPS_HEADER'})}
          </h5>
        )}
        {stepsCompleted === 6 && (
          <h5 className='mb-4 ms-6 m-0 text-white'>
            {intl.formatMessage({id: 'COMPLETED_STEPS_HEADER'})}
          </h5>
        )}
      </>
    )
  }

  const StepButton = ({linkTo, textId}) => (
    <Link to={linkTo} onClick={closeChecklist}>
      <button
        type='button'
        className='btn btn-sm py-3 ps-4 pe-1 bg-orange bg-hover-white text-white fw-bolder text-nowrap'
      >
        {intl.formatMessage({id: textId})}
        <KTSVG path='/media/gori/arrows/arrow_right.svg' className='svg-icon-2 text-white' />
      </button>
    </Link>
  )

  const StepContent = ({card, index}) => (
    <div className='d-flex flex-column col-9 pe-3'>
      <h6
        className={clsx('card-title fs-5 text-capitalize', {
          'text-decoration-line-through text-gray-600 mb-0': card.completed,
        })}
      >
        {`${index + 1}. ${intl.formatMessage({id: card.titleId})}`}
      </h6>
      {!card.completed && (
        <p className='card-text fs-8'>{intl.formatMessage({id: card.contentId})}</p>
      )}
    </div>
  )

  const StepActions = ({card}) => (
    <div
      className={clsx('d-flex flex-column justify-content-center align-items-start', {
        'd-none': !card.current,
        'd-block': card.current,
      })}
    >
      <StepButton linkTo={card.linkTo} textId='GO' />
      {card.canSkip && (
        <Button
          className='text-hover-dark border-0 bg-transparent p-0 fs-9'
          event={() => handleSkip(card.key)}
        >
          <span className='text-decoration-underline'>
            {intl.formatMessage({id: 'SKIP'})}
            <KTSVG path='/media/gori/arrows/arrow_skip.svg' />
          </span>
        </Button>
      )}
    </div>
  )

  const MultiLinkActions = ({card}) => (
    <div
      className={clsx('d-flex flex-row justify-content-start align-items-center mt-3 gap-2', {
        'd-none': !card.current,
        'd-block': card.current,
      })}
    >
      <StepButton linkTo={card.linkTo} textId='CREATE_FIRST_ORDER_SINGLE_BUTTON_TEXT' />
      <StepButton linkTo={card.linkTo2} textId='CREATE_FIRST_ORDER_BATCH_BUTTON_TEXT' />
    </div>
  )

  const CompletedChecklistOverlay = () =>
    stepsCompleted !== 6 ? null : (
      <div className='completed-checklist-overlay blurred-bg position-absolute top-0 left-0'>
        <div className='d-flex flex-column justify-content-center align-items-center gap-0'>
          <img
            src={toAbsoluteUrl('/media/gori/onboard/welcome/complted-onboarding-checklist.gif')}
            alt='Complted GIF'
            style={{width: '100px', height: '100px'}}
          />
          <p className='text-center fs-4 fw-boldest'>
            {intl.formatMessage({id: 'CHECKLIST_COMPLETED_MESSAGE_1'})}
            <br />
            {intl.formatMessage({id: 'CHECKLIST_COMPLETED_MESSAGE_2'})}
          </p>
          <div className={clsx('d-flex flex-row justify-content-start align-items-center gap-2')}>
            <StepButton linkTo='/orders/create' textId='CREATE_FIRST_ORDER_SINGLE_BUTTON_TEXT' />
            <StepButton linkTo='/batches' textId='CREATE_FIRST_ORDER_BATCH_BUTTON_TEXT' />
          </div>
          <div className='mt-2'>
            <span onClick={closeChecklist} className='onboarding-checklist-completed-font'>
              {intl.formatMessage({id: 'CHECKLIST_COMPLETED_MESSAGE_3'})}
            </span>
          </div>
        </div>
      </div>
    )

  return (
    <CSSTransition appear in timeout={600} classNames='pop' unmountOnExit>
      <div
        ref={checklistRef}
        className='d-block position-absolute onboarding-checklist'
        style={{top: '83px', right: '0px', zIndex: 9999, isolation: 'isolate'}}
      >
        <div className='card shadow-sm me-10 mw-384px' style={{width: '384px'}}>
          <div className='card-heading rounded-top py-4'>
            <div className='d-flex justify-content-between align-items-start'>
              <TutorialChecklistHeader />

              <div role='button' onClick={closeChecklist}>
                <KTSVG
                  path='/media/gori/onboard/welcome/minimize.svg'
                  className='svg-icon-2 text-white me-6'
                  svgClassName='text-white'
                />
              </div>
            </div>
            <div className='d-flex align-items-center px-6 mt-3'>
              <ProgressText />
              <ProgressBar />
            </div>
          </div>

          <div className='position-relative'>
            <CompletedChecklistOverlay />

            <ol className='card-body d-flex flex-column p-0 list-unstyled m-0'>
              {filteredSteps.map((card, index) => (
                <li
                  key={card.key}
                  className={clsx(
                    'py-4 px-6 d-flex',
                    {'border-bottom': index !== filteredSteps.length - 1},
                    {
                      'flex-row justify-content-between align-items-center': !(
                        index == 2 && !card.completed
                      ),
                    },
                    {'flex-column': index === 2 && !card.completed}
                  )}
                  style={{backgroundColor: card.current ? '#FFFAE8' : '#FFF'}}
                >
                  <StepContent card={card} index={index} />
                  {card.completed && (
                    <p className='m-0 text-primary fw-boldest d-flex flex-row flex-nowrap justify-content-end align-items-center'>
                      <span className='me-1'>{intl.formatMessage({id: 'COMPLETED'})}!</span>
                      <KTSVG path='media/gori/onboard/welcome/completed-check.svg' />
                    </p>
                  )}
                  {card.key === 'order' && card.linkTo2 ? (
                    <MultiLinkActions card={card} />
                  ) : (
                    <StepActions card={card} />
                  )}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export {TutorialChecklist}
