import {CSSTransition} from 'react-transition-group'
import {useCallback, useEffect} from 'react'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import {useNavigate, useSearchParams} from 'react-router-dom'
import SettingsService from '../../modules/settings/core/_requests'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {OrderType} from '../../modules/orders/core/_const'
import {isEmpty} from 'lodash'
import {ROUTES} from '../../../_gori/constants'

export function EbayVerifyPage() {
  const intl = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const connectEbay = useCallback(async () => {
    try {
      const res = await SettingsService.connect({
        store_type: OrderType.EBAY,
        code: searchParams.get('code'),
      })
      if (res) {
        toast.success(intl.formatMessage({id: 'CONNECTION_SUCCESSFUL'}))
      }
    } catch (error: any) {
      let message = error?.response?.data?.error?.message ?? ''
      toast.error(isEmpty(message) ? intl.formatMessage({id: 'CONNECTION_FAILED'}) : message)
    } finally {
      navigate(ROUTES.SETTINGS.CONNECTIONS.EBAY)
    }
  }, [intl, searchParams, navigate])

  useEffect(() => {
    connectEbay()
  }, [connectEbay])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}
