import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {cloneDeep, isEmpty, isEqual, isString} from 'lodash'
import React, {FC, useMemo} from 'react'
import Select from 'react-select'
import {DEFAULT_COLOR, INVALID_COLOR, VALID_COLOR} from '../../../constants'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string
  classNameSelect?: string
  label?: any
  options: Array<any>
  required?: boolean
  formik?: FormikProps<any>
  name?: string
  value?: any
  placeholder?: string
  emptyDefault?: boolean
  checkFormik?: boolean
  disabled?: boolean
  onFocus?: any
  styleInput?: any
  isSearchable?: boolean
  isClearValue?: boolean
  showMessageError?: boolean
  hasUseIntl?: boolean
  isMulti?: boolean
  formatGroupLabel?: any
}

const SelectFormik: FC<Props> = ({
  onChange = undefined,
  className = '',
  labelClassName = '',
  classNameSelect = '',
  label = null,
  options = [],
  required = false,
  formik,
  name = '',
  value = null,
  placeholder = '',
  emptyDefault = true,
  checkFormik = true,
  disabled = false,
  onFocus,
  styleInput,
  isSearchable = true,
  isClearValue = false,
  showMessageError = true,
  hasUseIntl = false,
  isMulti = false,
  formatGroupLabel,
}) => {
  let fieldProps = formik?.getFieldProps(name)
  let formikErrors = getIn(formik?.errors, name)
  let formikTouched = getIn(formik?.touched, name)

  let isInvalid = formik && formikTouched && formikErrors && checkFormik
  let isValid = formik && formikTouched && !formikErrors && fieldProps?.value && checkFormik
  const customStyles = {
    menuPortal: (base) => ({...base, zIndex: 9999, marginRight: '16px'}),
    option: (styles, {data, isSelected, isDisabled}) => ({
      ...styles,
      minHeight: 30,
      backgroundColor: isSelected && '#14B8A6',
      '&:hover': {
        background: (isDisabled && !isSelected && '#ffffff') || (!isSelected && '#f0fdfa'),
      },
      color: !isEmpty(data?.action) ? '#14B8A6' : isSelected ? '#ffffff' : '',
      fontWeight: !isEmpty(data?.action) ? 'bold' : '',
      cursor: isDisabled ? 'no-drop' : !isEmpty(data?.action || data?.value) ? 'pointer' : '',
      opacity: isDisabled && '0.5',
    }),
    control: (base) => ({
      ...base,
      background: (disabled && '#eff2f5') || '#ffffff',
      backgroundImage: isValid
        ? `url(${toAbsoluteUrl('/media/gori/valid.svg')})`
        : isInvalid
        ? `url(${toAbsoluteUrl('/media/gori/invalid.svg')})`
        : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 3.5rem center',
      backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
      borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
      '&:hover': {
        borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
      },
      boxShadow: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
      fontSize: '1.1rem',
      fontWeight: '500',
      color: '#5e6278',
      lineHeight: '1.5',
      padding: '3px',
      ...styleInput,
    }),
    valueContainer: (provided) => ({
      ...provided,
      marginRight: isMulti ? '30px' : '0px',
    }),
    singleValue: (base) => ({
      ...base,
      color: (disabled && '#5e6278') || 'defaultColor',
    }),
    placeholder: (base) => {
      return {
        ...base,
        fontSize: '0.95rem',
        fontWeight: '500',
        color: '#5e6278',
        lineHeight: '1.5',
      }
    },
  }
  const intl = useIntl()
  let selectOptions = useMemo(() => {
    let _options = cloneDeep(options)
    if (hasUseIntl) {
      _options = _options.map((item) => {
        item.label = isString(item.label)
          ? intl.formatMessage({id: item.label.toUpperCase()})
          : item.label
        return item
      })
    }

    if (emptyDefault) {
      return [{value: '', label: ''}, ..._options]
    }
    return _options
  }, [emptyDefault, hasUseIntl, intl, options])

  const handleClearValue = () => {
    let event = {target: {name: fieldProps?.name, value: null}}
    formik?.handleChange(event)
    if (onChange) {
      onChange(null)
    }
  }

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className='select-customs'>
          <Select
            isDisabled={disabled}
            name={name}
            className={clsx(classNameSelect)}
            menuPortalTarget={document.body}
            classNamePrefix='select-prefix'
            menuPosition={'fixed'}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
            })}
            options={selectOptions}
            value={
              value
                ? value
                : isMulti
                ? fieldProps?.value
                : selectOptions.reduce((acc, option) => {
                    if (option.options) {
                      const found = option.options.find((groupOption) =>
                        isEqual(groupOption.value, fieldProps?.value)
                      )
                      if (found) acc.push(found)
                    } else {
                      if (isEqual(option.value, fieldProps?.value)) acc.push(option)
                    }
                    return acc
                  }, []) || null
            }
            onBlur={() => {
              formik?.handleBlur({target: {name: fieldProps?.name}})
            }}
            placeholder={placeholder}
            onChange={async (option: any) => {
              if (formik) {
                let event
                if (isMulti) {
                  event = {target: {name: fieldProps?.name, value: option}}
                } else {
                  event = {target: {name: fieldProps?.name, value: option.value}}
                }
                await formik?.handleChange(event)
                await formik?.setFieldTouched(name, true)
              }
              if (onChange) {
                onChange(option)
              }
            }}
            onFocus={onFocus}
            isSearchable={isSearchable}
            isMulti={isMulti}
            isClearable={false}
            isOptionDisabled={(option) => option.isDisabled}
            noOptionsMessage={() => intl.formatMessage({id: 'NO_OPTIONS'})}
            {...(!!formatGroupLabel && {formatGroupLabel})}
          />
          {isClearValue && selectOptions.find((option) => option.value === fieldProps?.value) && (
            <div
              className='select-customs__icon-delete text-hover-danger cursor-pointer'
              onClick={handleClearValue}
            >
              <KTSVG path='/media/gori/orders/delete.svg' svgClassName='mh-10px' />
            </div>
          )}
        </div>
        {formikTouched && formikErrors && showMessageError && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              {typeof formikErrors === 'object' ? (
                Object.values(formikErrors).map((errorField: any, index) => (
                  <>
                    <span role='alert' key={index}>
                      {errorField}
                    </span>
                    <br />
                  </>
                ))
              ) : (
                <span role='alert'>{formikErrors}</span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {SelectFormik}
