import clsx from 'clsx'
import {find, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal, OverlayTrigger, Popover} from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, OPTION_COUNTRIES} from '../../../../../_gori/constants'
import {convertUserTimeZone, getShipmentFees, KTSVG} from '../../../../../_gori/helpers'
import {TableSkeleton} from '../../../../../_gori/helpers/components'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {
  Button,
  CarrierBadge,
  Table,
  TableBody,
  TableTabs,
  TableWrapper,
} from '../../../../../_gori/partials/widgets'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import {ReasonModal} from '../../../a1'
import {useAuth} from '../../../auth'
import {AddressView} from '../../../orders'
import {OPTIONS_CONTENT_TYPE} from '../../../orders/core/_const'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
}

const A1HoldOrderModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const {isCancel, newCancelToken} = useCancelToken()
  const {a1Batch} = useA1BatchesProvider()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers
  const pageCurrent = useRef(1)
  const [tableData, setTableData] = useState<any>([])
  const [statistic, setStatistic] = useState<any>([])
  const [loading, setLoading] = useState<{first: boolean; getOrder: boolean}>({
    first: true,
    getOrder: false,
  })
  const [totalPage, setTotalPage] = useState<number>(1)
  const [hasLoadMore, setHasLoadMore] = useState<boolean>(false)
  const [showPopover, setShowPopover] = useState<Record<string, boolean>>({})
  const [showModal, setShowModal] = useState<{reason: boolean}>({
    reason: false,
  })
  const [dataModal, setDataModal] = useState<{
    reasonData: {type: string; reason: string; first_release_status: string} | null
  }>({
    reasonData: null,
  })

  const tabName = useMemo(() => searchParams.get('release_status') || 'hold', [searchParams]) as
    | 'hold'
    | 'rejected'

  const configParams = useMemo(
    () => ({
      page: DEFAULT_PAGE,
      page_size: DEFAULT_PAGE_SIZE,
      first_held: true,
      status: searchParams.get('release_status') || A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value,
    }),
    [searchParams]
  )

  const setTable = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, first: true}))
      const config = {
        params: configParams,
        cancelToken: newCancelToken(),
      }
      const response = await A1BatchService.getA1BatchOrders(a1Batch.id, config)
      if (response) {
        setTableData(response.orders)
        setStatistic(response.statistics)
        setTotalPage(response.pagination.total_pages)
        setHasLoadMore(response.pagination.total_pages > 1)
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [a1Batch.id, configParams, isCancel, newCancelToken])

  const fetchMore = async () => {
    if (pageCurrent.current < totalPage) {
      pageCurrent.current++

      try {
        setLoading((prev) => ({...prev, getOrder: true}))
        const config = {
          params: {...configParams, page: pageCurrent.current},
          cancelToken: newCancelToken(),
        }
        const response = await A1BatchService.getA1BatchOrders(a1Batch.id, config)
        if (response) {
          setTableData((prev) => [...prev, ...response.orders])
        }
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, getOrder: false}))
      }
    } else {
      setHasLoadMore(false)
    }
  }

  useEffect(() => {
    setTable()

    return () => {}
  }, [setTable])
  const toolTipModal = useCallback(
    ({
      message,
      className = '',
      messageTooltip = '',
      classNameTooltip = '',
      placement = 'bottom',
    }) => {
      return (
        <OverlayTrigger
          placement={placement}
          show={showPopover.id}
          overlay={
            messageTooltip ? (
              <Popover
                className='mw-350px p-5'
                onMouseEnter={() => setShowPopover((prev) => ({...prev, tooltip: true}))}
                onMouseLeave={() => setShowPopover((prev) => ({...prev, tooltip: false}))}
              >
                <div className={clsx('fw-bold text-truncate-2', classNameTooltip)}>
                  {messageTooltip}
                </div>
              </Popover>
            ) : (
              <> </>
            )
          }
        >
          <div
            className={clsx('cursor-pointer text-truncate', className)}
            onMouseEnter={() => setShowPopover((prev) => ({...prev, tooltip: true}))}
            onMouseLeave={() => setShowPopover((prev) => ({...prev, tooltip: false}))}
          >
            {message}
          </div>
        </OverlayTrigger>
      )
    },
    [showPopover]
  )

  const columns = useMemo(() => {
    const _columns = [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return convertUserTimeZone(
            row.original?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        },
      },
      {
        id: 'code',
        Header: 'BAE ID',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => row.original?.bae_id,
      },
      {
        id: 'content',
        Header: intl.formatMessage({id: 'CONTENT'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original.items) && (
            <div>
              {row.original?.items.map((item: any) => {
                const content = find(OPTIONS_CONTENT_TYPE, {value: item?.a1?.content})
                return (
                  content && (
                    <div className='d-flex'>
                      <span className='mw-200px text-truncate'>
                        {intl.formatMessage({id: content?.label})}
                      </span>
                    </div>
                  )
                )
              })}
            </div>
          ),
      },
      {
        id: 'description',
        Header: intl.formatMessage({id: 'DESCRIPTION'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original.items) &&
          row.original?.items.map((item: any) => {
            return (
              item?.a1?.description && (
                <div className='d-flex'>
                  <span className='mw-200px text-truncate'>{item?.a1?.description}</span>
                </div>
              )
            )
          }),
      },
      {
        id: 'ship_to',
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return <AddressView data={row.original?.to} />
        },
      },
      {
        id: 'rate',
        Header: intl.formatMessage({id: 'RATE'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          let sum = 0
          let service = carriers?.[row.original.carrier + '_' + row.original.service] ?? []

          if (row.original?.fees) {
            sum = getShipmentFees(row.original?.fees)
          } else if (row.original?.estimated_rate) {
            sum = row.original?.estimated_rate
          }
          const isCheapest = row.original?.is_cheapest_rate ?? false
          return (
            row.original?.carrier && (
              <CarrierBadge
                service={service}
                isCheapest={isCheapest}
                {...((row.original?.fees || row.original?.estimated_rate) && {
                  cost: sum,
                })}
              />
            )
          )
        },
      },
      {
        id: 'container_id',
        Header: intl.formatMessage({id: 'CONTAINER_ID'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return row?.original?.a1?.container_id
        },
      },
      {
        id: 'hts_code',
        Header: intl.formatMessage({id: 'HTS_CODE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original.items) &&
          row.original?.items.map((item: any) => {
            return (
              item?.a1?.hs_tariff_number && (
                <div className='d-flex'>
                  <span className='mw-200px text-truncate'>{item?.a1?.hs_tariff_number}</span>
                </div>
              )
            )
          }),
      },
      {
        id: 'country',
        Header: intl.formatMessage({id: 'COUNTRY_OF_ORIGIN'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original.items) &&
          row.original?.items.map((item: any) => {
            const country = find(OPTION_COUNTRIES, {value: item?.a1?.country})
            return (
              country && (
                <div className='d-flex'>
                  <span className='mw-200px text-truncate'>{country?.label}</span>
                </div>
              )
            )
          }),
      },
      {
        id: 'quantity',
        Header: intl.formatMessage({id: 'QUANTITY'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original.items) &&
          row.original?.items.map((item: any) => {
            return (
              item?.a1?.quantity && (
                <div className='d-flex justify-content-center'>{item?.a1?.quantity}</div>
              )
            )
          }),
      },
      {
        id: 'value',
        Header: `${intl.formatMessage({id: 'VALUES'})} ($)`,
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center text-gray-800',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original.items) &&
          row.original?.items.map((item: any) => {
            return (
              item?.a1?.value && (
                <div className='d-flex justify-content-center'>{item?.a1?.value}</div>
              )
            )
          }),
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return (
            <>
              {row?.original?.tracking_number}
              {!isEmpty(row.original.error) && (
                <div className='row'>
                  <span className='text-danger'>{row.original?.error?.message}</span>
                  <span className='text-danger'>{row.original?.error?.Description}</span>
                  {row.original?.error?.fields &&
                    Object.entries(row.original.error.fields).map(([key, value]: [any, any]) => {
                      return (
                        <span key={key} className='text-danger'>
                          - {key} {value}
                        </span>
                      )
                    })}
                </div>
              )}
            </>
          )
        },
      },
      {
        id: 'hawb',
        Header: 'HAWB',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-gray-800',
        Cell: ({row}: {row: any}) => {
          return row?.original?.a1?.hawb
        },
      },
      {
        id: 'journey_status',
        Header: intl.formatMessage({id: 'JOURNEY_STATUS'}),
        headerClassName: 'min-w-150px text-center fixed-action right-0',
        cellClassName: 'text-center text-gray-800 fixed-action right-0',
        Cell: ({row}: {row: any}) => {
          const foundStatusOrder = find(Object.values(A1_CONSTANTS.OPTIONS_RELEASE_STATUS), {
            value: row?.original?.a1?.status,
          })
          return foundStatusOrder && intl.formatMessage({id: foundStatusOrder?.label})
        },
      },
    ]
    return _columns.filter((col) => col)
  }, [intl, currentUser, carriers, toolTipModal, tabName])

  const handleCloseModal = () => {
    handleClose()
    if (searchParams.get('release_status')) {
      searchParams.delete('release_status')
      setSearchParams(searchParams)
    }
  }

  return (
    <>
      {showModal.reason && (
        <ReasonModal
          show={showModal.reason}
          handleClose={() => setShowModal((prev) => ({...prev, reason: false}))}
          data={dataModal.reasonData}
          isDisabled={true}
        />
      )}
      <Modal
        id='gori_modal_a1_hold_order_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'JOURNEY_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <TableWrapper>
              <TableBody>
                {loading.first ? (
                  <TableSkeleton countRow={8} />
                ) : (
                  <InfiniteScroll
                    dataLength={tableData}
                    next={fetchMore}
                    hasMore={hasLoadMore}
                    height='50vh'
                    loader={
                      loading.getOrder && (
                        <div className='d-flex justify-content-center'>
                          <span className='spinner-border' />
                        </div>
                      )
                    }
                    endMessage={
                      <p className='d-flex justify-content-center'>
                        {intl.formatMessage({id: 'NO_MORE_ITEMS'})}
                      </p>
                    }
                  >
                    <TableTabs
                      dataTabs={statistic}
                      keyCheckActive='release_status'
                      disableTabAll={true}
                      children={
                        <TableWrapper className='rounded-top-left-0-dt border-top border-light'>
                          <TableBody className='px-0'>
                            <Table
                              columns={columns}
                              data={tableData}
                              tbodyClass='text-gray-600 fw-bold pb-10'
                            />
                          </TableBody>
                        </TableWrapper>
                      }
                    />
                  </InfiniteScroll>
                )}
              </TableBody>
            </TableWrapper>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CLOSE'})}
                event={handleCloseModal}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {A1HoldOrderModal}
